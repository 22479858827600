import request from '@/utils/request_tg';

class User {
  static SetOrigin(data) {
    return request({
      url: '/crm/userPreferences/saveUserPreferences',
      method: 'post',
      data
    });
  }

  static GetOrigin(params) {
    return request({
      url: '/crm/userPreferences/getUserPreferencesByUsername',
      method: 'get',
      params
    });
  }

  static CheckToken(params) {
    return request({
      url: '/crm/account/checkToken',
      method: 'get',
      params
    });
  }
  // 用户信息
  static PersonalCenter(params) {
    return request({
      url: '/crm/personalCenter/list',
      method: 'get',
      params
    });
  }
  // //绑定的微信信息
  // static getWxList(params) {
  //   return request({
  //     url: '/crm/wxLogin/list',
  //     method: 'get',
  //     params
  //   });
  // }

  // 前台微信登录
  static wxLoginBind(params) {
    return request({
      url: '/crm/wxLogin/home',
      method: 'get',
      params
    });
  }
  // 前台微信登录绑定
  static wxLoginHomeBind(data) {
    return request({
      url: '/crm/wxLogin/homeBind',
      method: 'post',
      data
    });
  }
  //   用户地图使用技术
  static setMapCount(params) {
    return request({
      url: '/crm/addService/getMap',
      method: 'get',
      params,
      headers: {
        Origin: 'https://test.tmj.mmcuav.cn',
        Referer: 'https://test.tmj.mmcuav.cn'
      }
    });
  }
}

export default User;
