import request from '@/utils/request';
class MessageAPI {
  // 消息数量
  static getMsgCount = () =>
    request({
      url: '/tss/notice/web/count',
      method: 'GET'
    });
  // 消息列表
  static getMsgList = (data) =>
    request({
      url: '/tss/notice/web/page',
      method: 'POST',
      data
    });
  // 消息详情
  static getMsgDetailById = ({ id }) =>
    request({
      url: `/tss/notice/read/${id}`,
      method: 'POST'
    });
}
export default MessageAPI;
