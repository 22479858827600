import Vue from 'vue';
import store from '../store';

Vue.directive('pms', {
  inserted: function (el, binding, vnode) {
    const keys = binding.value; // 权限为数组 eg: [任务列表, 编辑]
    const permission = store.state.permission;

    if (!Array.isArray(keys) || keys.length == 0) {
      return;
    }

    if (keys[0] === undefined) {
      return;
    }

    if (!Array.isArray(permission)) {
      return;
    }

    let hasPms = false;
    // 找到对应的权限，用find实现找到后退出循环
    permission.find((item) => {
      if (item.meta.title === keys[0]) {
        if (keys.length === 1) {
          // 判断是否为一级菜单权限
          hasPms = true;
          return true;
        } else {
          // 判断是否为二级按钮权限
          if (Array.isArray(item.children)) {
            return item.children.find((btn) => {
              if (btn.meta.title === keys[1]) {
                hasPms = true;
                return true;
              }
            });
          } else if (Array.isArray(item.btns)) {
            return item.btns.find((btn) => {
              if (btn.meta.title === keys[1]) {
                hasPms = true;
                return true;
              }
            });
          }
        }
      }
    });

    // 无权限删除按钮
    /* if (!hasPms) {
      el.parentNode.removeChild(el);
    } */
  }
});
