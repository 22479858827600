import request from '@/utils/request_tg';
import request_nasas from '@/utils/request_nasas';


class LoginAPI {
  // NASAS 接口 start
  /**
   * 登录
   * @param {*} data
   * @returns
   */
  static nasasLogin(data) {
    return request_nasas({
      url: '/api/v1/login',
      method: 'post',
      data
    });
  }
  // 1.5获取IMSI数据
  static queryIMSIData(data) {
    return request_nasas({
      url: '/api/v1/queryIMSIData',
      method: 'post',
      data
    });
  }
  /**
   * 用户登录
   * @param {*} data
   * @returns
   */
  static newLogin(data) {
    return request({
      url: '/crm/login',
      method: 'post',
      data
    });
  }

  /**
   * 加密狗登录
   * @param {*} data
   * @returns
   */
  static loginByUsbkey(data) {
    return request({
      url: `/crm/account/loginByUsbkey?usbkey=${data.usbkey}`,
      method: 'post',
      data
    });
  }

  /**
   * 微信二维码登录
   * @param {*}
   * @returns
   */
  static wxLogin(data) {
    return request({
      url: '/crm/wxLogin/getWxCode',
      method: 'get'
      // data
    });
  }

  /**
   * 微信token登录
   * @param {*}
   * @returns
   */
  static wxToken(data) {
    return request({
      url: `/crm/wxLogin/callBack?code=${data.code}&state=${data.state}`,
      method: 'get'
      // data
    });
  }

  /**
   * 获取账号权限
   * @param {*} params
   * @returns
   */
  static permission(params) {
    return request({
      url: '/crm/user/platformPermission',
      method: 'get',
      params
    });
  }

  /**
   * 后台权限刷新
   * @param {*} params 
   * @returns 
   */
  static managePermission(params) {
    return request({
      url: '/crm/user/managePermission',
      method: 'get',
      params
    });
  }

  /**
   * 子平台权限刷新
   * @param {*} params 
   * @returns 
   */
  static subPlatformPermission(params) {
    return request({
      url: '/crm/user/subPlatformPermission',
      method: 'get',
      params
    });
  }

  /**
   * 获取微信二维码
   * @param {*} params
   * @returns
   */
  static getWxCodeImg(params) {
    return request({
      url: '/crm/wxLogin/getWxCode',
      method: 'get',
      params
    });
  }

  /**
   * 外部接入授权登录接口
   */
  static appSecretlogin(params) {
    return request({
      url: '/crm/appSecretlogin',
      method: 'post',
      params
    });
  }
}

export default LoginAPI;
