<!--
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:14:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-23 16:29:31
-->
<template>
  <div class="demo-video">
    <video ref="video" src="https://mmc-crm.oss-cn-shenzhen.aliyuncs.com/prod/demo.542ab55a.mp4" controls autoplay controlslist="nodownload" />
    <div class="close" @click="onClose">
      <span class="icon iconfont">&#xe699;</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'DemoVideo',
  methods: {
    ...mapMutations(['SET_SHOW_DEMO']),
    onClose() {
      if (this.$refs.video.ended) {
        this.SET_SHOW_DEMO(false);
      } else {
        const h = this.$createElement;
        this.$msgbox({
          title: this.$t('operation.Hint'),
          message: h('div', null, [
            h('div', null, this.$t('home.hello')),
            h('div', null, this.$t('home.noWatchStepHint'))
          ]),
          showCancelButton: true,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.SET_SHOW_DEMO(false);
            }
            done();
          }
        }).then((action) => {});
      }
    }
  }
};
</script>

<style scoped lang="scss">
.demo-video {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  video {
    position: absolute;
    height: calc(100vh - 48px);
    width: 100vw;
    left: 0;
    top: 48px;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 52px;
    right: 4px;
    width: 24px;
    height: 24px;
    background: #000;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;

    .icon {
      font-size: 20px;
    }
  }
}
</style>
