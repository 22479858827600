/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:17:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-12-04 15:49:57
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:17:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-23 14:57:22
 */
export default {
  tmj: '天目将',
  operation:{
    Operation: '操作',
    Add: '新增',
    Edit: '编辑',
    Delete: '删除',
    Search: '搜索',
    Reset: '重置',
    Save: '保存',
    Cancel: '取消',
    Confirm: '确定',
    Close: '关闭',
    Detail: '详情',
    Export: '导出',
    Import: '导入',
    Upload: '上传',
    Download: '下载',
    Create: '创建',
    Hint: '提示',
    Preview: '预览',
    Check: '查看'
  },

  login: {
    inputUserName: '请输入用户名',
    inputPassword: '请输入密码',
    userName: '用户名',
    password: '密码',
    login: '登录',
    loginSuccess:'登录成功',
    languageChange: '语言切换',
    accountLogin: '账号密码',
    QRcodeLogin: '微信扫码',
    readAndAgreement: '我已阅读并同意',
    userAgreement: '用户协议',
    and:'和',
    privacyProtection: '隐私保护',
    jumpToScanPage: '请点击前往登陆页扫码',
    checkDogle: '请检查加密狗是否正确插入',
    readProtocol: '请阅读协议！',
    accountExpired: "账号已过有效期，请联系管理员！",
    logoutMessage: '退出成功!'
  },
  dict:{
    PendingExecution: "待执行",
    InExecution: "执行中",
    Completed: "已完成",
    PendingApproval: '待审批',
    NoApprovalRequired: "无需审批",
    Rejected: "已驳回",
    Launch: "发起",
    Approve: "审批",
    Execute: "执行",
    Pass: "通过",
    NoPass: '不通过',
    NoOperation: "未操作",
  },
  layout: {
    SwitchMap: '切换地图',
    NoSetOrigin: '暂未设置原点',
    setSuccess: "设置成功",
    selectAtLeastThree : '请最少选择三个点',
    stere: "立方米",
    ExcavatedSquare: '挖方体积',
    fillSquare: "填方体积",
    flyIntoOrigin: "飞入原点",
    settingOrigin: '设置原点',
    horizontalMeasurement: "水平测量",
    verticalMeasurement : "垂直测量",
    areaMeasurement: "面积测量",
    volumeMeasurement: "体积测量",
    measurement: "测量工具",
    view: "视图",
    apicalView: "顶视",
    overlook: "俯视",
    northArrow: "指北",
    humanPerspective: "人视角",
    fullScreen: "全屏",
    toolbar: "工具栏",
  
  },

  header:{
    signalValue: '信号值',
    ParachuteIsNormal: "伞包正常",
    ParachuteEnabled: "伞包未启用",
    ControllerOutline : "控制器不在线",
    parachuteDisconnected: '伞包未连接',
    parachuteFailure: '降落伞故障',
    parachuteInAlert: '降落伞进入警戒',
    parachuteOutAlert: '降落伞退出警戒',
    parachuteOut: '降落伞开伞',
    uavGPSInformation: "无人机gps信息",
    StarSearchNumber: "搜星数",
    RockerOnline: "摇杆在线",
    InfieldAuthority: '内场权限',
    FieldAuthority: "外场权限",
    MessageCenter: '消息中心',
    NotificationOfTask: "任务通知",
    AIWarningTips: "AI预警提示",
    SearchByTaskName: "请输入任务名称搜索",
    To: "至",
    startDate: '开始日期',
    endDate: '结束日期',
    messageType: "消息类型",
    query: "查询",
    messageTime: '消息时间',    
    serialNumber: "序号",
    viewAlerts: "查看预警",
    warningTime: "预警时间",
    rangeOfWarning: "预警范围",
    unit: "单位",
    detectionValue: "检测值",
    earlyWarningGas: "预警气体",
    latitudeAndLongitudeAddress: "经纬度所在地址",
    AIRecognitionType: 'AI识别类型',
    locationSearch: '地点搜索',
    remark: '备注',
    pleaseInputRemark: '请输入备注',
  },

  home:{
    home:'首页',
    flightApplication: '飞行应用',
    flightManagement: '飞行管理',
    industryApplication: "行业应用",
    resourceManagement: "资源管控",
    threeDimensionalApplications: "三维应用",
    takeOverMessage: '请先接管无人机，然后再操作无人机作业',
    select: '选择',
    pleaseSelect: '请选择',
    CancelledOperation: "已取消操作",
    noPermissionLogout: '您还未登录，是否跳转到登录页？',
    no: '暂无',
    flying: '飞行中',
    electricQuantity: "电量",
    flightSpeed: '飞行速度',
    flightAltitude: '飞行高度',
    flightMileage: '飞行里程',
    flightTime: '飞行时间',
    altitude: '海拔高度',
    startingDistance: '起点距离',
    climbRate: '爬升率',
    GroundSpeed: '地速',
    NumberOfSatellites: '卫星数目',
    Voltage: '电压',
    Battery: '电池',
    FlightMode: '飞行模式',
    uav: '无人机',
    SelectedUAV: '已选无人机',
    flightPositioning: '飞行定位',
    flightPath: '飞行轨迹',
    pauseFlight: '暂停飞行',
    keyToReturn: '一键返航',
    safeLanding: '安全降落',
    guidanceFlight: '指点飞行',
    automatic: '自动',
    manual: '手动',
    takeover: '接管',
    takeoverUAV: '接管无人机',
    takeOverSuccess: "成功接管",
    takeOver: '接管',
    takeOverOrNot: '是否接管',
    exitTakeOver: '退出接管',
    HaveBeenTakenOver: "已经退出接管",
    isExitTakeOver: "是否退出接管",
    SafetyConfirmation: "安全确认",
    returnFlightMode: '返航模式',
    GPSMode:'GPS模式',
    flightRouteMode: '航线模式',
    fourSplitScreen: '四分屏',
    nineSplitScreen: '九分屏',
    uavInfomation: '无人机信息',
    downloadCenter: '下载中心',
    customerService: '联系客服',
    systemVideo: '系统视频',
    backgroundManagement: '后台管理',
    exit: '退出',
    demonstration: '演示',
    hello: '您好 ！,',
    noWatchStepHint: '指引步骤未观看确认要跳过吗？',
    longitude: '经度',
    latitude: '纬度',
    height: '高度',
    direction: '方向',
    speed: '速度',
    allocationSetting: '分配设置',
    longitudeAndLatitude: "经纬度",
    定点模式: '定点模式',
    保持模式: '保持模式',
    跟随模式: '跟随模式',
    定高模式: '定高模式',
    手动模式: '手动模式',
    特技模式: '特技模式',
    板外模式: '板外模式',
    自稳模式: '自稳模式',
    起飞模式: '起飞模式',
    离线状态: '离线状态'
  },

  navibar: {
    uavApplication: '无人机应用',
    nestApplicaition: '鹰巢应用', 
    uavList:'无人机列表',
    RouteManagement: "航线管理",
    ProjectList: "项目列表",
    PilotList: "飞手列表",
    ServiceNetworkList: "服务网点列表",
    TrainingNetworkList: "培训网点列表",
    DisplayNetworkList: "展示网点列表",
    RunningProjectList: "运行项目列表",
    ThreeDApplications: "三维应用",
    uavMission: "无人机任务",
    AerieTask: "鹰巢任务",
    FlightLog: "飞行日志",
    AirspaceManagement: "空域管理",
    EagleNestList: "鹰巢列表",
    WarningRecord: "预警记录", 
    BaseMap: "基础地图",
    MultiplePhaseContrast: "多期对比",
    RegionalAnalysis: "区域分析",
    PanoramicDisplay : "全景展示",
    Total: '总共',
    unit: '位',
  },
  
  flightApplication: {
    uavName: '无人机名称',
    inpueUavName: "请输入无人机名称/机构名称",
    libraryOfTasks: "任务库",
    TaskName: "任务名称",
    TaskType: "任务类型",
    TaskDetail: '任务详情',
    TaskFlow: "任务流程",
    TaskDescription: '任务描述',
    ResponsibleAgency: "责任机构",
    ExecutionStatus: "执行状态",
    ExecutionTime: "执行时间",
    pleaseSelectExecutionTime: "请选择执行时间",
    CreateTime: '创建时间',
    EndTask: "结束任务",
    ValidityPeriod: "有效期",
    StartAutomatic: "启动自动",
    loading: "加载数据中",
    inputNestName: "请输入鹰巢名称/机构名称",
    pleaseInputAirspaceName: "请输入空域名称",
    New: "新增",
    Airspace: "空域",
    newAirspace: '新增空域',
    airspaceName: '空域名称',
    airspaceType: '空域类型',
    airspaceStatus: "空域状态",
    pleaseSelectAirspaceType: "请选择空域类型",
    pleaseSelectOrganization: "请选择机构",
    pleaseDrawRegion: "请绘制区域或输入地理位置",
    pleaseSelectStatus: "请选择状态",
    pleaseSelectColor: "请选择颜色",
    limitHeight: '限制高度',
    pleaseInputLimitHeight: "请输入限制高度",
    pleaseSelectFirstPoint: "请点击选择第一个点",
    pleaseSelectSecondPoint: "请选择第二个点",
    PleaseSelectAtLeastThreePoints: "请最少选择三个点",
    routeId: "航线id",
    newRoutes: '新增航线',
    inputRouteName: '请输入航线名称',   
    routeName: '航线名称',
    pleaseSelectRoute: '请选择航线名称',
    numberOfWaypoints: '航点数量',
    lengthOfRoute: "航线长度",
    saveTime: "保存时间",
    scheduledTime: "预计时间",
    supportRouteFormat: "支持上传json,kml格式",
    airSpaceHiddenMsg: "此空域已隐藏,无法显示!",    
    HangarList: "机巢列表",
    inboard: "舱内",
    outboard: "舱外",
    mounting: "挂载",
    OneClickecognition: "一键识别",
    MonitoringAndDefense: "监控布防",
  
    "查看详情": "查看详情",
    "企业信息": "企业信息",
    "联系人": "联系人",
    "应用类型": "应用类型",
    "联系人电话": "联系人电话",
    "应用": "应用",
    "联系人邮箱": "联系人邮箱",
    "版本": "版本",
    "项目定位": "项目定位",
    "备注描述": "备注描述",
    "鹰巢未在线": "鹰巢未在线",
    "内部应用": "内部应用",
    "外部应用": "外部应用",
    "推广应用": "推广应用",
    "标准版": "标准版",
    "专业版": "专业版",
    "旗舰版": "旗舰版",
    "接管鹰巢": "接管鹰巢",
    "流配置未配置": "流配置未配置",
    "无人机离线中": "无人机离线中",
    "一键任务": "一键任务",

    "本月": "本月",
    "本周": "本周",
    "今日": "今日",
    "日期": "日期",
    "统计模式": "统计模式",
    "总任务数量": "总任务数量",
    "台": "台",
    "总飞行架次": "总飞行架次",
    "次": "次",
    "总飞行里程": "总飞行里程",
    "总飞行时长": "总飞行时长",
    "总照片数量": "总照片数量",
    "张": "张",
    "飞行架次号": "飞行架次号", 
    "飞行时长": "飞行时长",
    "ai预警总次数": "ai预警总次数",
    "所属任务": "所属任务",
    "图片": '图片',
    "记录回放": "记录回放",
    "飞行报告": "飞行报告",
    "时间": "时间",
    "名称": "名称",
    "视频": "视频",
    "架次": "架次",
    "航线规划": "航线规划",
    "航线编辑": "航线编辑",
    "请选择责任机构": "请选择责任机构",
    "默认高度": "默认高度",
    "请输入默认高度":"请输入默认高度！",
    "默认速度": "默认速度",
    "默认偏航角": "默认偏航角",
    "默认俯仰角": "默认俯仰角",
    "绘制航点": "绘制航点",  
    "规划航线": "规划航线",
    "导入航线": "导入航线",
    "终点降落": "终点降落",
    "否": "否",
    "是": "是",
    "绘制方式": "绘制方式",
    "选择航线": "选择航线",
    "添加成功": "添加成功！",
    "修改成功": "修改成功！",
    "修改失败": "修改失败！",
    "操作成功": "操作成功！",
    "删除成功": "删除成功! ",
    "删除失败": "删除失败!",
    "识别成功": "识别成功!",
    "截屏成功": "截屏成功！",
    "截屏失败": "截屏失败！",
    "出错了": "出错了！",
    "启用": "启用",
    "停用": "停用",
    "坐标内容": "坐标内容",
    "所属机构": "所属机构",
    "请选择所属机构": "请选择所属机构",
    "色值": "色值",
    "绘制多边形": "绘制多边形",
    "绘制圆形": "绘制圆形",
    "电子围栏": "电子围栏",
    "禁飞区": "禁飞区",
    "限高区": "限高区",

    "拍照": "拍照",
    "录屏": "录屏",
    "截屏": "截屏",
    "AI预警": "AI预警",
    "选择算法":"选择算法",
    "请选择算法": "请选择算法",
    "抽帧间隔": "抽帧间隔",
    "标准": "标准",
    "自定义": "自定义",
    "抽帧频率": "抽帧频率",
    "秒/次": "秒/次",
    "停止布防": "停止布防",
    "编辑布防": "编辑布防",
    "确认布防": "确认布防",
    "气体监测预警": "气体监测预警",
    "水位监测预警": "水位监测预警",
    "喷火器": "喷火器",
    "保险": "保险",
    "喷火": "喷火",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",
    // "": "",


  },
  resourceManagement:{
    resourceType: "资源类型",
    resourceName: "资源名称",
    resourceAddress: "资源地址",
  },
  industryApplication:{
    pleaseInputProjectName: '请输入项目名称',
    projectName: '项目名称',
    enter: '进入',
    noData: '暂无数据',
    
  },

  threeDApplication: {
    layer:  "图层",
    label: "标注",
    tag: "标签",
    editTag: "编辑标签",
    SplitScreenContrast: "分屏对比",
    PleaseInputTagName: '输入标签名称',
    PleaseInputLabelName: '输入标注名称',
    SelectLabelToDelete: "请选择需要删除的标注！",
    preview: "预览",
    screenshot: "截图",
    addDotAnnotation: "添加点标注",
    addLineAnnotation: "添加线标注",
    addFaceAnnotation: "添加面标注",
    pleaseSelectLabel: '请选择标签',
    all: "全部",
    level: "等级",
    pleaseSelectLevel: '请选择等级',
    addTag: '添加标签',
    typeManagement: '类型管理',
    import: '导入',
    export: '导出',
    tagTypeManagement: '标签类型管理',
    showOrHide: '显示/隐藏',
    labelType: '标签类型',
    inputName: "输入名称",
    pleaseInputTagType: "请输入标签类型",
  },

  taskMangement: {
    "常态任务": "常态任务",
    "定时任务": "定时任务",
    "周期任务": "周期任务",
    "创建任务": '创建任务',
    "任务状态": "任务状态",
    "责任单位": "责任单位",
    "重新发起": "重新发起",
    "审批": "审批",
    "请检查任务状态是否为待审批": "请检查任务状态是否为待审批!",
    "请检查任务状态是否为待执行": "请检查任务状态是否为待执行!",
    "确定执行当前任务": "确定执行当前任务？",
    "请检查当前无人机是否在线": "任务发送失败，请检查当前无人机是否在线！",
    "任务审批": "任务审批",
    "驳回": "驳回",
    "驳回原因": "驳回原因",
    "请输入驳回原因": "请输入驳回原因",
    "审批成功": "审批成功",
    "基本信息": "基本信息",
    "正常或通过": "正常或通过",
    "异常或驳回": "异常或驳回",
    "请输入任务名称": "请输入任务名称",
    "请选择任务流程": "请选择任务流程",
    "任务场景": "任务场景",
    "请选择任务场景": "请选择任务场景",
    "请选择执行状态": "请选择执行状态",

    "开始时间": "开始时间",
    "请选择预计开始时间": "请选择预计开始时间",
    "定时执行时间": "定时执行时间",
    "周期类型": "周期类型",
    "每日": "每日",
    "每周": "每周",
    "每月": "每月",
    "周期日历": "周期日历",
    "请选择周期日历": "请选择周期日历",
    "请选择周期类型": "请选择周期类型",
    "有效期限": "有效期限",
    "任务流程详情": "任务流程详情",
    "流程节点名称": "流程节点名称",
    "节点功能": "节点功能",
    "指派操作员": "指派操作员",
    "指派类型": "指派类型",
    "操作状态": "操作状态",
    "实际操作员": "实际操作员",
    "实际操作员机构": "实际操作员机构",
    "操作时间": "操作时间",
    "任务创建": "任务创建",
    "获取设备列表失败": "获取设备列表失败！",
    "最后一天": "最后一天",
    "任务发起": "任务发起",
    "派出所审批": "派出所审批",
    "任务执行": "任务执行",
    "任务编辑": "任务编辑",
    "指定用户": "指定用户",
    "指定机构": "指定机构",
    "周一": "周一",
    "周二": "周二",
    "周三": "周三",
    "周四": "周四",
    "周五": "周五",
    "周六": "周六",
    "周日": "周日",
    "历史回放":"历史回放",
    "AI预警数":"AI预警数",
    "取证上传图片数":"取证上传图片数",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",
    // "":"",


  }

}