/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:17:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-16 11:28:54
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import locales from "@/locales";
import enLocale from 'element-ui/lib/locale/lang/en'; // 英文语言包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'; // 中文语言包
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n);

const messages = {
  'en': {
    ...enLocale,
    ...locales['en'],
  },
  'zh-CN': {
    ...zhLocale,
    ...locales['zh-CN'],
  }
};

const localLang = JSON.parse(localStorage.getItem('tmj'))?.locale?.curLocale
// 判断当前语言环境
const lang = localLang || navigator.language || navigator.browserLanguage;

console.log("当前浏览器语言:", lang);

const i18n = new VueI18n({
  locale: lang,
  messages: messages,
});

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;
