import request from '@/utils/request';

class flightTaskAPI {
  /**
 * 结束鹰巢周期定时任务
 * @param {*} taskldj
 * @returns
 */
  static nest_task_end(taskldj) {
    return request({
      url: `/tss/nest_task/${taskldj}/end`,
      method: 'patch'
    });
  }
  /**
   * 飞行任务列表 1
   * @param {*} data
   * @returns
   */
  static getFlightList(data) {
    return request({
      url: `/tmj/task/getTaskList`,
      method: 'post',
      data
    });
  }
  /**
   * 飞行任务列表 3 期
   * @param {*} data
   * @returns
   */
  static tssTaskPage(data) {
    return request({
      url: `/tss/task/web/page`,
      method: 'post',
      data
    });
  }

  /**
   * 流程选择 3 期
   * @param {*} projectId
   * @returns
   */
  static processProcessBoxProjectId(projectId) {
    return request({
      url: `/tss/process/processBox/${projectId}`,
      method: 'get'
    });
  }

  /**
   * 创建飞行任务 3 期
   * @param {*} data
   * @returns
   */
  static tssTask(data) {
    return request({
      url: `/tss/task`,
      method: 'POST',
      data
    });
  }

  // 编辑航线 3 期修改航线
  static editFlight(data) {
    return request({
      url: `/tss/task`,
      method: 'put',
      data
    });
  }

  static reCreateTask(data) {
    return request({
      url: `/tss/task/reCreate`,
      method: 'post',
      data
    });
  }

  // 获取无人机列表
  static getUav(params) {
    return request({
      url: `/tmj/device/getDeviceSelect`,
      method: 'get',
      params
    });
  }

  // 获取无任务无人机列表
  static getUnbindUav(params) {
    return request({
      url: `/tmj/device/getDeviceSelect`,
      method: 'get',
      params
    });
  }
  // 飞行任务创建任务获取无人机列表
  static getUavflyList(params) {
    return request({
      url: `/dms/uav/page`,
      method: 'get',
      params
    });
  }
  // 获取组织列表
  static getCompany(data) {
    return request({
      url: `/crm/organization/getOrganizationSelect`,
      method: 'get',
      data
    });
  }

  // 获取航线
  static getflight(data) {
    return request({
      url: `/tmj/route/getRouteSelect`,
      method: 'get',
      data
    });
  }

  // 新增航线
  static addFlight(data) {
    return request({
      url: `/tmj/task/createTask`,
      method: 'post',
      data
    });
  }

  // 编辑航线
  static changeFlight(data) {
    return request({
      url: `/dms/route/update`,
      method: 'put',
      data
    });
  }

  // 设备在线状态
  static getDeviceOnlineState(data) {
    return request({
      url: `/tmj/device/deviceIsOnline`,
      method: 'post',
      data
    });
  }

  // 设备任务状态
  static isDeviceHaveTask(data) {
    return request({
      url: `/tmj/device/deviceHaveTask`,
      method: 'post',
      data
    });
  }

  // 获取单个无人机数据最后一次上传
  static deviceData(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return request({
      url: `/tmj/device/deviceData`,
      method: 'post',
      data: formData
    });
  }

  // 任务列表
  static getTaskSelect(data) {
    return request({
      url: `/tmj/task/getTaskSelect`,
      method: 'get',
      data
    });
  }

  // 任务开始
  static taskStart(params) {
    return request({
      url: `/tmj/task/startTask`,
      method: 'get',
      params
    });
  }

  // 任务完成
  static taskEnd(params) {
    return request({
      url: `/tmj/task/endTask`,
      method: 'get',
      params
    });
  }

  // 图片查询
  static getTaskImages(params) {
    return request({
      url: `/tmj/task/getPhotograph`,
      method: 'get',
      params
    });
  }

  // 保存图片
  static savaTaskImages(data) {
    return request({
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      url: `/tmj/task/photograph`,
      method: 'post',
      data
    });
  }

  // 导出报告
  static getExportToWord(params) {
    return request({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
      url: `/tmj/taskPointExportToWordV1`,
      method: 'GET',
      params
    });
  }

  // 开始任务
  static startTask(params) {
    return request({
      url: '/tmj/task/startTask',
      method: 'GET',
      params
    });
  }

  // 删除任务
  static deleteTask(data) {
    return request({
      url: `/tss/task/${data}`,
      method: 'DELETE'
    });
  }

  // 审批任务
  static approveTask(data) {
    return request({
      url: `/tss/task/approve`,
      method: 'post',
      data
    });
  }
}

export default flightTaskAPI;
