import request from '@/utils/request';

class Airspace {

  /**
 * 预警提示分页列表
 * @param {*} params
 * @returns
 */
  static earlyWarning(params) {
    return request({
      url: '/dms/sortie-image/earlyWarning',
      method: 'get',
      params
    });
  }
  /**
 * 已读
 * @param {*} data
 * @returns
 */
  static readWarning(data) {
    return request({
      url: `/dms/sortie-image/readWarning?userId=${data.userId}&imageId=${data.imageId}`,
      method: 'post',
    });
  }
  /**
   * 空域列表
   * @param {*} params
   * @returns
   */
  static airspaceList(params) {
    return request({
      url: '/dms/airspace/page',
      method: 'get',
      params
    });
  }

  /**
   * 空域添加
   * @param {*} data
   * @returns
   */
  static Add(data) {
    return request({
      url: '/dms/airspace/add',
      method: 'post',
      data
    });
  }

  /**
   * 空域修改
   * @param {*} data
   * @returns
   */
  static Edit(data) {
    return request({
      url: '/dms/airspace/update',
      method: 'put',
      data
    });
  }

  /**
   * 空域删除
   * @param {*} data
   * @returns
   */
  static Delete(params) {
    return request({
      url: `/dms/airspace/delete/${params.id}`,
      method: 'delete'
    });
  }
}

export default Airspace;
