/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-09 10:43:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-11-16 14:37:11
 */

const state = {
    curLocale: 'zh-CN',
    // 多语言
    localeMap: [
        {
            lang: 'zh-CN',
            name: '简体中文'
        },
        {
            lang: 'en',
            name: 'English'
        }
    ],
    reqHeaderMap:{
        "zh-CN": 'zh',
        "en": "en"
    }
}

const getters = {
    getLocale: state => state.curLocale,
    getLocaleMap: state => state.localeMap,
}

const mutations = {
    setLocale(state, locale) {
        state.curLocale = locale;
    },
}

const actions = {
    changeLocale({ commit }, locale) {
      commit('setLocale', locale);
    }
}

export default {
    namespaced: true,
    name: 'locale',
    state,
    getters,
    mutations,
    actions
};
