<template>
  <div id="container" style="height: 100%" />
</template>

<script>
import { feature } from '@turf/helpers';
import chinaJson from './chinaJson.js';
import * as echarts from 'echarts';
import * as turf from '@turf/turf';

const chinaMap = chinaJson;
const province_area = { name: null, area: [], value: 0 };
let china_area = [];
let navList = [];
export default {
  data() {
    return {
      chinaJson: chinaJson,
      navName: '',
      option: {},
      itemList: [],
      provincesList: [],
      areaColor: '#1A232C',
      borderColor: '#24A4FF',
      myChart: {}
    };
  },
  watch: {
    areaColor: {
      deep: true,
      handler: function (newVal, oldVal) {
        const self = this;
        self.myChart.dispose();
        self.myChart = {};
        self.init_echartMap();
        self.hover();
      }
    }
  },

  mounted() {
    this.get_province_area();
    this.init_echartMap();
    this.bus_handleHeaderItem();
    this.hover();
  },
  methods: {
    hover() {
      const self = this;
      // console.log("ccc1:", self.myChart);
      self.myChart.on('mousemove', function (params) {
        // console.log("xxx:", params.color);
        if (params.color) {
          self.areaColor = params.color;
          self.borderColor = '#FFF';
          // self.myChart.setOption(self.option, true);
          // console.log("ccc2:", self.myChart);
          // self.hover()
          // self.myChart.setOption(self.option);
        }
      });
    },

    bus_handleHeaderItem() {
      const self = this;
      this.$bus.$on('handleHeaderItem', (list, bjshow, e) => {
        navList = list;
        self.navName = e.name;
        console.log(navList);
        const pointsFromList = JSON.parse(JSON.stringify(navList)).map((val) => {
          const arr = [];
          arr.push(val.lon, val.lat);
          return arr;
        });
        const pointsInList = turf.points(pointsFromList);
        const pointsFormPolygon = JSON.parse(JSON.stringify(china_area));
        pointsFormPolygon.forEach((val) => {
          let area = [];
          if (val.name == '河北省') {
            area = val.area[0];
          } else {
            area = val.area;
          }
          const searchWithin = turf.polygon([area]);
          const rightPoint = turf.pointsWithinPolygon(pointsInList, searchWithin);
          val.value = rightPoint.features.length;
        });
        this.provincesList = pointsFormPolygon.map((val) => {
          const params = {
            name: val.name,
            value: val.value
          };
          return params;
        });
        // this.option.series[0].name = self.name
        // self.myChart.dispose()
        // self.myChart = {}
        // self.init_echartMap()
      });
    },
    bus_handlePositions(e) {
      this.$bus.$emit('handlePositions', e);
    },
    init_echartMap() {
      const self = this;
      var dom = document.getElementById('container');
      self.myChart = echarts.init(dom);
      var app = {};
      // var option;
      // console.log("chinaJosn:", chinaJson);
      // echarts.registerMap("china", {
      //   geoJSON: chinaJson,
      // });
      // var ROOT_PATH =
      //   "https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples";

      // myChart.showLoading();
      // $.when(
      //   // $.get(ROOT_PATH + "/data/asset/geo/USA.json"),
      //   // // $get("https://github.com/haihaigang/china-demo/blob/master/src/china.js"),
      //   $.getScript("https://cdn.jsdelivr.net/npm/d3-array"),
      //   $.getScript("https://cdn.jsdelivr.net/npm/d3-geo")
      // ).done(function (res) {
      const { chinaJson } = self;
      console.log('res:', chinaJson);
      // const projection = d3.geoAlbersUsa();
      // myChart.hideLoading();
      echarts.registerMap('CHINA', chinaJson);

      self.myChart.on('click', function (params) {
        self.$emit('changeIsCesiumState');
        const position = self.myChart.convertFromPixel('series', [
          params.event.offsetX,
          params.event.offsetY
        ]);
        self.bus_handlePositions(position);
        console.log('p', position);
      });

      // myChart.on('mousemove', function (params) {
      //   // console.log(params.color);
      //   if (params.color) {
      //     self.areaColor = params.color
      //     myChart.setOption(self.option, true);
      //     // self.init_echartMap()
      //   }
      // });

      this.option = {
        backgroundColor: '#0A141C', // 背景颜色
        // backgroundColor: {
        //   type: "pattern",
        //   repeat: "no repeat",
        //   image: '~@/assets/loginImage/BG.png'
        // },
        title: {
          text: 'China Population Estimates (2012)',
          subtext: 'Data from www.census.gov',
          sublink: 'http://www.census.gov/popest/data/datasets.html',
          left: 'right',
          show: true
        },
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          borderColor: '#50ABFF',
          backgroundColor: '#1E2026',
          textStyle: {
            color: '#A4FF64'
          }
        },
        visualMap: {
          left: 'right',
          min: 0,
          // max: 100,
          max: 10,
          textStyle: {
            color: '#fff'
          },
          inRange: {
            color: ['#FFFF5B', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
          },
          text: ['高', '低'],
          calculable: true
        },
        toolbox: {
          show: true,
          // orient: 'vertical',
          left: 'left',
          top: 'top',
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        series: [
          {
            name: self.navName,
            type: 'map',
            map: 'CHINA',
            itemStyle: {
              areaColor: '#1A232C', // 地图颜色
              borderColor: '#808284',
              borderWidth: 1
              // color: "#1E2026"
            },
            // 地区
            emphasis: {
              // disabled: true
              label: {
                show: true,
                color: '#fff'
                // color: '#eee'
              },
              itemStyle: {
                // areaColor: "#1A232C",
                areaColor: self.areaColor,
                borderColor: self.borderColor,
                shadowColor: '#24A4FF',
                // borderColor: "#FFFFFF",
                // shadowColor: '#FFFFFF',
                borderWidth: 1.5,
                shadowBlur: 15
              }
            },
            select: {
              // disabled: true,
              label: {
                show: true,
                color: '#eee'
              },
              itemStyle: {
                areaColor: '#1A232C',
                borderColor: '#24A4FF',
                borderWidth: 1.5,
                shadowColor: '#24A4FF',
                shadowBlur: 10
              }
            },
            // projection: {
            //   project: function (point) {
            //     return projection(point);
            //   },
            //   unproject: function (point) {
            //     return projection.invert(point);
            //   },
            // },
            data: self.provincesList
          }
        ]
      };
      self.myChart.setOption(this.option, true);
      // });

      if (this.option && typeof this.option === 'object') {
        self.myChart.setOption(this.option, true);
      }
    },
    get_province_area() {
      china_area = JSON.parse(JSON.stringify(chinaMap)).features.map((val) => {
        const province_area = {
          name: val.properties.name,
          area: val.geometry.coordinates[0],
          value: 0
        };
        return province_area;
      });
    }
  }
};
</script>

<style>
#container {
  z-index: 2;
}
</style>
