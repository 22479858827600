import request from '@/utils/request';

class AirLine {
  /**
   * 航线列表
   * @param {*} params
   * @returns
   */
  static lineList(params) {
    return request({
      url: '/dms/route/page',
      method: 'get',
      params
    });
  }

  /**
   * 航线规划
   * @param {*} data
   * @returns
   */
  static Edit(data) {
    return request({
      url: '/dms/route/add',
      method: 'post',
      data
    });
  }
  static routeDelete(id) {
    return request({
      url: `/dms/route/delete/${id}`,
      method: 'delete'
    });
  }

  /**
   * 航线修改
   * @param {*} data
   * @returns
   */
  static Change(data) {
    return request({
      url: '/dms/route/update',
      method: 'put',
      data
    });
  }

  /**
   * 航线详情
   * @param {*} data
   * @returns
   */
  static details(params) {
    return request({
      url: `/tmj/route/getRouteDetail/${params}`,
      method: 'get',
      params
    });
  }

  /**
   * 上传航线
   * @param {*} data
   * @returns
   */
  static upload(data) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: '/dms/route/upload',
      method: 'post',
      data
    });
  }
  /**
   * 上传航线
   * @param {*} data
   * @returns
   */
  static uploadFile(data) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: '/crm/dimensionMark/uploadFile',
      method: 'post',
      data
    });
  }

  /**
   * 上传多条航线
   * @param {*} data
   * @returns
   */
  static uploadRoutes(data) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: '/dms/route/upload',
      method: 'post',
      data
    });
  }
  /**
   * 航线删除
   * @param {*} data
   * @returns
   */
  static deleteRoute(params) {
    return request({
      url: `/dms/route/delete/${params}`,
      method: 'delete'
    });
  }

  /**
   * 航线详情
   * @param {*} params
   * @returns
   */
  static routeDetail(params) {
    return request({
      url: '/dms/route/id',
      method: 'get',
      params
    });
  }

  /**
   * 飞行日志
   * @param {*} params
   * @returns
   */
  static getSorties(params) {
    return request({
      url: '/dms/route/id',
      method: 'get',
      params
    });
  }
  /**
   * 飞行日志-历史视频
   * @param {*} params
   * @returns
   */
  static getSortiesHistoryVideo(params) {
    return request({
      url: '/dms/sortie/history-video',
      method: 'get',
      params
    });
  }
  /**
   * 飞行日志-架次-历史轨迹
   * @param {*} params
   * @returns
   */
  static getSortiesTrajectory(params) {
    return request({
      url: '/dms/sortie/data',
      method: 'get',
      params
    });
  }
}

export default AirLine;
