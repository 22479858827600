import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
// 引用element-ui的加载和消息提示组件
// import { Loading } from "element-ui";
import { resetMessage } from './message';
import { saveAs } from 'file-saver';

const {reqHeaderMap} = store.state.locale

const $axios = axios.create({
  // 设置超时时间
  // timeout: 30000,
  // 基础url，会在请求url中自动添加前置链接
  baseURL: process.env.VUE_APP_BASE_NASAS_API
});

Vue.prototype.$http = axios;

const loading = null;

/**
 * 请求拦截器
 * 用于处理请求前添加loading、判断是否已保存token，并在每次请求头部添加token
 */
$axios.interceptors.request.use(
  (config) => {
    // let FLYINGSESSIONID = localStorage.getItem("FLYINGSESSIONID");
    // let mmcIdentity = localStorage.getItem("mmcIdentity");
    const token = localStorage.getItem('nasas_token');
    if (token) {
      config.headers['token'] = token; // 测试token用例:607753147d46ba48b1ac30a4ad3d60cd
      config.headers['channel'] = 'channel'; // 渠道 主平台 子平台 后台
    }
    config.headers['Locale'] = reqHeaderMap?.[store.state.locale?.curLocale]
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * 响应拦截器
 * 用于处理loading状态关闭、请求成功回调、响应错误处理
 */
$axios.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close();
    }
    // 飞行成功导出（特殊处理）
    if (response.headers.url) {
      saveAs(response.headers.url, '飞行报告');
      return Promise.reject(response);
    }
    const status = response.status;
    const res = response.data;
    // 请求成功返回response.data
    if ((status >= 200 && status < 300) || status === 304) {
      switch (res.code) {
        case 200:
          return Promise.resolve(response.data);
        case 525:
          if (store.state.isIframe) {
            resetMessage.error(response.data.msg);
          }
          return Promise.resolve(response.data);
        case 505:
          console.log('请求返回了69 ');
          store.commit('user/LOGIN_OUT');
          router.replace({
            path: '/login'
          });
          return Promise.reject(response);

        case 401:
          // console.log('暂时去掉 ')
          // resetMessage.error('登录失效');
          // store.commit('user/LOGIN_OUT');
          // router.replace({
          //   path: '/login'
          // });
          return Promise.reject(response);

        default:
          if (response.config.responseType === 'blob') {
            return Promise.resolve(response.data);
          }
          resetMessage.error(response?.data?.msg || '网络请求错误');
          return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  async (error) => {
    if (loading) {
      loading.close();
    }
    console.log(error);
    if (error.response) {
      switch (error.response.code) {
        case 401:
          // 返回401 清除token信息并跳转到登陆页面
          // store.commit("user/LOGIN_OUT");
          // router.replace({
          //   path: "/login",
          //   query: {
          //     redirect: router.currentRoute.fullPath,
          //   },
          // });
          break;
        case 403:
          // 返回403 清除token信息并跳转到登陆页面
          // store.commit("user/LOGIN_OUT");
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          });
          if (store.state.isIframe) {
            resetMessage.error(error.response.msg);
          }
          break;
        case 404:
          resetMessage.error('网络请求不存在');
          break;
        default:
          // 统一错误提示弹窗
          if (error.response.data instanceof Blob) {
            try {
              const jsonStr = await error.response.data.text();
              const data = JSON.parse(jsonStr);
              console.log(response);

              data.msg ? resetMessage.error(data.msg) : resetMessage.error('网络请求错误');
            } catch (e) {}
          } else {
            resetMessage.error(error.response?.data?.msg || '网络请求错误');
          }
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        resetMessage.error('请求超时！请检查网络是否正常');
      } else {
        resetMessage.error('请求失败，请检查网络是否已连接');
      }
    }
    return Promise.reject(error);
  }
);

export default $axios;
