import request_tg from '@/utils/request_tg';
import request from '@/utils/request';

class TgApi {
  //机载视图列表
  static devicephoto(params) {
    return request.get(process.env.VUE_APP_LOOK_URL + "/log-file-record/list", 
      {
        params
      }
    );
  }
  /**
* 查询未读消息
* 
* @param {*} params
* @returns
*/
  static warningCount(params) {
    console.log(params, 'params');
    return request({
      url: '/dms/sortie-image/warningCount',
      method: 'get',
      params
    });
  }

  /**
 * 查询架次号
 * 
 * @param {*} params
 * @returns
 */
  static uavLastData(params) {
    console.log(params, 'params');
    return request({
      url: '/dms/uav/realTimeData',
      method: 'get',
      params
    });
  }
  /**
   * ai列表
   * @param {*} params
   * @returns
   */

  static getAiIconListFromAdmin(params) {
    return request({
      url: '/crm/algorithm/algorithms',
      method: 'get',
      params
    });
  }

  // 获取无人机列表
  static getUavList(params) {
    return request({
      // url: "/tmj/servicePoint/getServicePointListMap",
      // url: '/tmj/fly/getflyListMap',
      url: '/dms/uav/page',
      method: 'get',
      params
    });
  }

  // 获取飞手列表
  static getFlyHandList(params) {
    return request({
      url: '/tmj/flyMan/getflyManListMap',
      method: 'get',
      params
    });
  }

  // 获取服务网点数据
  static getTrainPointListMap(params) {
    return request({
      url: '/tmj/trainPoint/getTrainPointListMap',
      method: 'get',
      params
    });
  }

  // 获取培训网点数据
  static getServicePointListMap(params) {
    return request({
      url: '/tmj/servicePoint/getServicePointListMap',
      method: 'get',
      params
    });
  }

  // 获取展示网点数据
  static getExhibitionPointListMap(params) {
    return request({
      url: '/tmj/exhibitionPoint/getExhibitionPointListMap',
      method: 'get',
      params
    });
  }

  // 获取培训运行项目列表
  static getRunProjectListSimple(params) {
    return request({
      // url: '/tmj/runProject/getRunProjectListSimple',
      url: '/crm/application/getAppAndProject',
      method: 'get',
      params
    });
  }

  // 获取架次列表
  static getSortie(params) {
    return request({
      url: '/dms/sortie/page',
      method: 'get',
      params
    });
  }
  // 获取架次图片列表
  static getSortieImage(params) {
    return request({
      url: '/dms/sortie-image/page',
      method: 'get',
      params
    });
  }
  // 获取架次视频列表
  static getSortieVideo(params) {
    return request({
      url: '/dms/sortie/history-video',
      method: 'get',
      params
    });
  }
  // 导出成果
  static getExportToWord(params) {
    return request({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
      url: `/dms/sortie/history-video`,
      method: 'GET',
      params
    });
  }
  // 导出成果
  static getExportToWordForSortie(params) {
    return request({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
      url: `/dms/sortie/export-word`,
      method: 'GET',
      params
    });
  }
  // 导出成果
  // static getExportToWordForSortie(params) {
  //   return request({
  //     url: `/dms/sortie/export-word`,
  //     method: 'GET',
  //     params
  //   });
  // }
  // 接管无人机
  static setUavControlOn(params) {
    return request({
      url: `/dms/uav/takeOver/${params.id}`,
      method: 'get',
      params
    });
  }
  // 解除无人机控制
  static setUavControlOff(id) {
    return request({
      url: `/dms/uav/cancelTakeOver/${id}`,
      method: 'delete'
    });
  }
  // 根据机构id获取航线列表
  static getRouteList(params) {
    return request({
      url: `/dms/route/getRouteList`,
      method: 'get',
      params
    });
  }
  // 架次分页
  static getSortieList(params) {
    return request({
      url: `/dms/sortie/page`,
      method: 'get',
      params
    });
  }
  // 属性查询机构下拉列表
  static getProcessList(params) {
    return request({
      url: `/crm/organization/tree`,
      method: 'get',
      params
    });
  }
  // 上传文件
  static uploadFile(data) {
    return request({
      url: `/crm/upload`,
      method: 'post',
      data
    });
  }
}

export default TgApi;
