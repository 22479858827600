import store from '@/store/index';
import { Control_API } from '@/api';
import mount from '@/components/mount';
import dayjs from 'dayjs';
let positions = []; // 飞机走过的点
let position = null
const defaultPos = {
  latitude: 22.433, // 纬度
  longitude: 113.75 // 经度
};
window.state = function () {
  return store.state;
};

const uavRealTimeData = {
  attitude: {
    roll: 0, // 飞机的俯仰值：向前为正，向后为负。单位为度。
    pitch: 0, // 飞机的横滚值：正向为正，反向为负。单位为度。
    yaw: 0 // 飞机的偏航值，其中0对应于正北航向。顺时针偏航会增加偏航值。
  },
  parachute: 1, // 降落伞状态 0正常，1未启用，2控制器不在线，3伞包未连接，4降落伞故障
  parachuteState: 1, // 1降落伞进入警戒，2降落伞退出警戒，3降落伞开伞
  accelerator: 0, // 飞机油门通道值
  locationCoordinate3D: {
    // 地理位置信息
    altitude: 0, // 相对高度，如果需要获取绝对高度 请查看Gps类
    latitude: defaultPos.latitude, // 纬度
    longitude: defaultPos.longitude // 经度
  },
  gps: {
    absoluteAlt: 0, // 绝对高度，以米为单位
    relativeAlt: 0, // 相对高度，以米为单位
    satelliteCount: 0 // 搜星数，卫星数越大，GPS信号越强
  },
  flyDistance: 0, // 当前飞行里程
  distanceToHome: 0, // 距离home的位置
  flyTime: 0, // 获取飞行时长，只记录飞机起飞到飞机降落的时间单位(毫秒),下次飞机起飞清零
  groundSpeed: 0, // hud地速，单位m/s, 飞行速度
  velocityZ: 0, // 使用NED（北-东-下）坐标系，飞机在z方向上的当前速度，以米/秒为单位, 爬升率
  voltage: 0, // 返回当前电池电压（V）
  chargeRemaining: 0 // 电池中的剩余能量百分比
};

const state = {
  // 键盘控制飞机
  keyControlFlag: false,
  // 刷新未读总数
  refreshWarningCount: null,
  openTest: false, // 开启测试
  cesiumViewer: null, // cesium的viewer实例
  uav: null, // 选择中的无人机信息
  uavLog: null,
  // 无人机的实时采集数据
  uavRealTimeData,
  airlineEntity: null, // 航线实例
  uavModelEntity: null, // 飞机模型实例
  smuTime: false, // 模拟上报定时器
  airlineData: null, // 当前选择的航线数据
  flyMode: 'auto', // 飞行模式, auto: 自动, manual: 手动,
  showVideo: false, // 显示视频
  showAIVideo: false, // 显示AI视频
  showViewLibrary: false, // 显示视图库
  showMenu: false, // 显示操作菜单
  mountList: [
    {
      gimbalName: "MMC_Gimbal_ZT60R",
      mountId: 12,
    },
  ], // 挂载列表
  selectMount: null, // 选中的挂载
  // 关联无人机数据面板上的航线
  routeId: null,
  // 控制无人机数据面板上的解锁
  fly_control_unlock: null,
  // 任务列表数据
  TaskList: {},
  ygValue: null,
  FlyModelFlag: true, // 飞行模式控制
  getPageBasicPropertyInfosById: null, // 资源id
  isPreventClick: false, // 是否可点击
  mapRadio: 1, // 分屏模式时 代表当前控制的是第几个地图实例
  dimensionReset: null,//关闭三维标注弹框
  // 无人机日志
  msg_list: [],
  wrjType: {},
  // 鹰巢数据
  jcData: {},
  // 健康管理数据
  healthData: {},
  payloadR3: [],
  move_data: {},
  payloadZT60R: {},
  // 当前飞机返回挂载的payload数据 
  payload: {},
  // 无人机飞行日志是否打开
  showOperationLog: false,
};

const mutations = {
  /**
   * 单纯的给state赋值
   * @param {*} param0
   * @param {*} data {key: '', value}
   */
  setState(state, data) {
    try {
      state[data.key] = data.value;
    } catch (e) {
      console.log('setDate err', e);
    }
  }
};

const actions = {
  /**
   * 销毁无人机相关对象
   * @param {} param0
   * @param {*} data
   */
  destroy({ commit, state, dispatch }, data) {
    viewer.entities.remove(state.airlineEntity);
    viewer.entities.remove(state.uavModelEntity);
    commit('setState', { key: 'uav', value: null });
    commit('setState', { key: 'uavLog', value: null });
    commit('setState', { key: 'airlineEntity', value: null });
    commit('setState', { key: 'uavModelEntity', value: null });
    commit('setState', { key: 'airlineData', value: null });
    commit('setState', { key: 'flyMode', value: 'auto' });
    commit('setState', { key: 'mountList', value: [] });
    commit('setState', { key: 'selectMount', value: null });
    commit('setState', { key: 'showVideo', value: false });
    commit('setState', { key: 'showViewLibrary', value: false });
    commit('setState', { key: 'showAIVideo', value: false });
    commit('setState', { key: 'showMenu', value: false });
    commit('setState', { key: 'uavRealTimeData', value: uavRealTimeData });
    commit('setState', { key: 'msg_list', value: [] });
    commit('setState', { key: 'wrjType', value: {} });
    commit('setState', { key: 'jcData', value: {} });
    commit('setState', { key: 'healthData', value: {} });
    commit('setState', { key: 'payloadR3', value: [] });
    commit('setState', { key: 'move_data', value: {} });
    commit('setState', { key: 'showOperationLog', value: null });
    positions = [];
    position = null
  },
  /**
   * 开始测试
   * @param {*} param0
   * @param {*} data.open 是否开启
   */
  // test({ commit, dispatch }, { open }) {
  //   commit("setState", { key: "openTest", value: open });
  //   dispatch("smuUavRealTimeData", { open });
  // },
  /**
   * 模拟无人机实时数据上报
   * @param {*} param0
   * @param {boolean} data.open 开关
   */
  smuUavRealTimeData({ commit, state, dispatch }, data) {
    // 目前只支持飞控中心链路
    if (data.open) {
      const time = setInterval(() => {
        if (!state.uav) {
          return;
        }
        const data = {
          locationCoordinate3D: {
            // 地理位置信息
            altitude: Math.random() * 10, // 相对高度，如果需要获取绝对高度 请查看Gps类
            latitude: 31.23 + Math.random() * 0.01, // 纬度
            longitude: 121.47 + Math.random() * 0.01 // 经度
          }
        };
        dispatch('updateByMQTT', {
          258: { data },
          259: {
            data: {
              gimbalName: 'MMC_Gimbal_Z40Pro',
              mountId: 11,
              mountType: 1036,
              payload: [165, 9, 3, 0, 57],
              widgetHeight: 500,
              widgetWidth: 540
            }
          }
        });
      }, 10000);
      commit('setState', { key: 'smuTime', value: time });
    } else {
      clearInterval(state.smuTime);
    }
  },
  // 通过mqtt更新实时采集数据
  updateByMQTT({ commit, state, dispatch }, data) {
    if (state.uav.network == 1) {
      // mqtt链路
      const type258 = data[258]?.data || {};
      const type268 = data[268]?.data || {};
      const type257 = data[257]?.data || {};
      const type259 = data[259]?.data;
      const type2006 = data[2006]?.data;
      const type2017 = data[2017]?.data;
      const type270 = data[270]?.data;
      const type260 = data[260]?.data;
      const type275 = data[275]?.data;
      const type2063 = data[2063]?.data;



      // 健康管理数据
      if (data) {
        commit('setState', {
          key: 'healthData',
          value: type275
        });
      }
      // console.log(data[2065], 'data[2065]');
      // // 鹰巢数据保存
      // if (data[2065]) {
      //   state.jcData = type2065 || {};
      // }
      if (data[270]) {
        // 无人机运行之日保存
        commit('setState', {
          key: 'wrjType',
          value: type270
        });
        const newData = {
          type: type270.code,
          text: type270.text,
          time: dayjs().format('YYYY-MM-DD HH:mm:ss')
        };
        const firstTwoItems = state.msg_list.slice(0, 5);
        const shouldStopLoading = firstTwoItems.some((item) => {
          return item.text === newData.text;
        });
        if (!shouldStopLoading) {
          state.msg_list.unshift(newData);
        }
      }
      // 机库系统消息数据
      if (data[260]) {
        const newData = {
          type: "警告",
          grade: type260.severity,
          text: type260.msg,
          time: dayjs().format('YYYY-MM-DD HH:mm:ss')
        };
        const firstTwoItems = state.msg_list.slice(0, 5);
        const shouldStopLoading = firstTwoItems.some((item) => {
          return item.text === newData.text;
        });
        if (!shouldStopLoading) {
          state.msg_list.unshift(newData);
        }

      }
      // if (data[type2063]) {
      //   const newData = {
      //     type: "2063",
      //     text: type2063.text,
      //     time: dayjs().format('YYYY-MM-DD HH:mm:ss')
      //   };
      //   const firstTwoItems = state.msg_list.slice(0, 2);
      //   const shouldStopLoading = firstTwoItems.some((item) => {
      //     return  item.text === newData.text;
      //   });
      //   if (!shouldStopLoading) {
      //     state.msg_list.unshift(newData);
      //   }

      // }

      // gps 需要判断使用哪个
      if (type258.rtk?.isMainSensor) {
        type258.gps = type258.rtk;
      }
      commit('setState', {
        key: 'uavRealTimeData',
        value: {
          ...state.uavRealTimeData,
          ...type258,
          ...type268,
          ...type257,
          ...type2006,
          ...type2017
        }
      });

      if (type259) {
        // 通过返回的259类型,获取挂载列表
        const find = state.mountList.find((item) => item.mountId === type259.mountId);
        if (!find) {
          state.mountList.push(type259);
          state.mountList.forEach((item) => {
            const find = mount.list.find((item1) => {
              return item1.name === item.gimbalName;
            });
            if (find) {
              item.icon = find.icon;
            }
          });
          commit('setState', { key: 'mountList', value: [...state.mountList] });
        }
        if (type259.gimbalName == 'MMC_Gimbal_R3') {
          commit('setState', { key: 'payloadR3', value: type259.payload });
        }

        commit('setState', {
          key: 'payload',
          value: {
            ...state.payload,
            [type259.gimbalName]: {
              gimbalName: type259.gimbalName,
              payload: type259.payload,
            }
          }
        });
      }



    } else {
      const uavInfo = data?.deviceData?.data?.uavInfo;

      if (uavInfo) {
        // 地面站链路
        commit('setState', {
          key: 'uavRealTimeData',
          value: {
            ...state.uavRealTimeData,
            attitude: {
              roll: uavInfo.roll, // 飞机的俯仰值：向前为正，向后为负。单位为度。
              pitch: uavInfo.pitch, // 飞机的横滚值：正向为正，反向为负。单位为度。
              yaw: uavInfo.yaw // 飞机的偏航值，其中0对应于正北航向。顺时针偏航会增加偏航值。
            },
            accelerator: 0, // 飞机油门通道值
            locationCoordinate3D: {
              // 地理位置信息
              altitude: 0, // 相对高度，如果需要获取绝对高度 请查看Gps类
              latitude: uavInfo.latitude, // 纬度
              longitude: uavInfo.longitude // 经度
            },
            gps: {
              absoluteAlt: uavInfo.altitude, // 绝对高度，以米为单位
              relativeAlt: uavInfo.height, // 相对高度，以米为单位
              satelliteCount: 0 // 搜星数，卫星数越大，GPS信号越强
            },
            flyDistance: uavInfo.flightDistance, // 当前飞行里程
            distanceToHome: uavInfo.distanceToHome, // 距离home的位置
            flyTime: uavInfo.flightTime, // 获取飞行时长，只记录飞机起飞到飞机降落的时间单位(毫秒),下次飞机起飞清零
            groundSpeed: uavInfo.groundSpeed, // hud地速，单位m/s, 飞行速度
            velocityZ: uavInfo.climbRate, // 使用NED（北-东-下）坐标系，飞机在z方向上的当前速度，以米/秒为单位, 爬升率
            voltage: uavInfo.voltage, // 返回当前电池电压（V）
            chargeRemaining: uavInfo.battaryRemain, // 电池中的剩余能量百分比
            flightSortie: uavInfo.flightSortie // 本次架次
          }
        });
      }

      // 挂载列表
      const mountList =
        data?.deviceData?.data?.mountInfo?.map((item) => {
          const find = mount.list.find((item1) => {
            return item1.name === item.mountName;
          });
          if (find) {
            item.icon = find.icon;
          }
          return {
            gimbalName: item.mountName,
            mountId: item.mountType,
            icon: item.icon
          };
        }) || [];
      commit('setState', { key: 'mountList', value: mountList });
    }

    if (state.uavRealTimeData.locationCoordinate3D.longitude) {
      if (
        state.uavRealTimeData.locationCoordinate3D.longitude === defaultPos.longitude &&
        state.uavRealTimeData.locationCoordinate3D.latitude === defaultPos.latitude
      ) {
        // 默认坐标不记录
      } else {
        const posData = UAVDataParser(state.uavRealTimeData);
        if (!position) {
          position = posData.position
        }
        // 解决 航线分叉问题
        if (state.uavModelEntity) {
          let model = state.uavModelEntity
          const curPos = model.position.getValue(
            Cesium.JulianDate.now()
          );
          if (curPos) {
            const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
            const afterCart = new Cesium.Cartesian3(posData.position.x, posData.position.y, posData.position.z);
            if (curCart.equals(afterCart)) {
            } else {
              // 航线数据
              positions.push(posData.position);
              position = posData.position
            }
          } else {
          }
        }
      }
      // window.positions = positions;

      if (!state.uavModelEntity) {
        dispatch('createUavModel');
        dispatch('createAirline');
        dispatch('flyToUavModel');
      }
    }
  },
  /**
   * 创建飞机模型
   * @param {*} param0
   * @param {*} data
   */
  createUavModel({ state, commit }, data) {
    if (!state.uavModelEntity) {
      const defaultModelUrl = 'cesium/model/1800_20220816_no_effect283g2color.gltf'
      const uri = process.env.VUE_APP_ENV === 'dev' ? defaultModelUrl : state.uav.modelVictor || defaultModelUrl
      const entity = viewer.entities.add({
        id: 'model_' + state.uav.hardId,
        orientation: new Cesium.CallbackProperty(() => {
          const posData = UAVDataParser(state.uavRealTimeData);
          return posData.orientation;
        }, false),
        position: new Cesium.CallbackProperty(() => {
          const posData = position;
          return posData;
        }, false),
        model: {
          uri,
          minimumPixelSize: 100,
          maximumScale: 20000,
          scale: 1,
          // color: Cesium.Color.fromCssColorString('#550088'), // 颜色
          // color: Cesium.Color.fromAlpha(Cesium.Color.BLACK, parseFloat(1.0)), //包含透明度的颜色
          // colorBlendMode: Cesium.ColorBlendMode['MIX']
        },
        label: {
          text: state.uav.name || '',
          font: '14pt monospace',
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 2,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -20),
          eyeOffset: new Cesium.Cartesian3(0, 0, -10)
        }
      });
      commit('setState', { key: 'uavModelEntity', value: entity });
    }
  },
  /**
   * 创建轨迹航线
   * @param {*} param0
   * @param {*} data
   */
  createAirline({ state, commit }, data) {
    if (!state.airlineEntity) {
      // 创建航线
      const airlineEntity = state.cesiumViewer.entities.add({
        id: 'airline_' + state.uav.hardId,
        polyline: {
          positions: new Cesium.CallbackProperty((time, result) => {
            return positions;
          }, false),
          width: 5,
          show: true,
          material: Cesium.Color.fromCssColorString('#6DD400')
        }
      });
      airlineEntity.show = true;

      commit('setState', { key: 'airlineEntity', value: airlineEntity });
    }
  },
  /**
   * 镜头飞向飞机模型
   * @param {*} param0
   * @param {*} data
   */
  flyToUavModel({ state, commit }, data) {
    console.log(state.uavModelEntity, 'state.uavModelEntity')
    if (state.uavModelEntity) {
      state.cesiumViewer.flyTo(state.uavModelEntity, {
        offset: new Cesium.HeadingPitchRange(0, -Cesium.Math.PI_OVER_TWO, 10000)
      });
    }
  },

  /**
   * 一键起飞
   * @param {function} data.callback  //完成回调
   */
  async takeOff({ state }, data) {
    const waypointActionType = {
      '拍照': 'START_TAKE_PHOTO',
      '悬停': 'STAY',
      '间隔拍照': 'TRIGGER',
      '开始录制': 'START_RECORD',
      '停止录制': 'START_RECORD',
      '偏航': 'ROTATE_AIRCRAFT',
      '俯仰': 'GIMBAL_PITCH',
      '变焦': 'CAMERA_ZOOM'
    }
    if (state.uav.network == 1) {
      // 航线转为飞控中心所需格式
      const oldwaypointList = JSON.parse(state.airlineData?.content);
      let waypointList = []
      if (oldwaypointList.content.length > 0) {
        for (let i = 0; i < oldwaypointList.content.length; i++) {
          const line = oldwaypointList.content[i];
          const item = {
            "altitude": line.alt,
            "coordinate": {
              "latitude": line.latitude,
              "longitude": line.longitude
            },
            "frame": 3,//3相对高度 0 是绝对高度
            "speed": line.speed,
            // "stay": 0,悬停
            "waypointActions": []
          }
          let waypointActions = []
          if (line.actions.length > 0) {
            for (let j = 0; j < line.actions.length; j++) {
              const action = line.actions[j];
              const item = {
                "actionParam": action.label == '悬停' ? action.value * 1000 : action.value,
                "actionType": waypointActionType[action.label]
              }
              waypointActions.push(item)
            }
          }

          item.waypointActions = waypointActions
          waypointList.push(item)
        }
      }
      try {
        // 生成架次號
        const getFlightSortic = await Control_API.getFlightSortic({
          taskId: state.airlineData.id,
          deviceHardId: state.uav.hardId
        });
        // 上传航线指令
        store.dispatch('MMCMQTT/publish', {
          topic: 'PX4/OBTAIN/' + state.uav.hardId,
          data: {
            type: store.state.MMCMQTT.orders.航线上传,
            data: {
              taskId: state.airlineData.id,
              flightSortiesID: getFlightSortic.data,
              waypointList: waypointList,
              autoFlightSpeed: 6,
              finishedAction: oldwaypointList.finishedAction,
              headingMode: 'AUTO',
              isExitMissionOnRCSignalLostEnabled: true,
              maxFlightSpeed: 12,
            }
          },
          callback() { }
        });

        // 告诉飞控开始任务,并且把架次号和 任务id传过去
        store.dispatch('MMCMQTT/publish', {
          topic: 'PX4/OBTAIN/' + state.uav.hardId,
          data: {
            type: store.state.MMCMQTT.orders.绑定任务id,
            data: {
              taskId: state.airlineData.id,
              flightSortiesID: getFlightSortic.data
            }
          },
          callback() { }
        });

        // 更改任务状态

        // 起飞指令
        setTimeout(() => {
          store.dispatch('MMCMQTT/publish', {
            topic: 'PX4/OBTAIN/' + state.uav.hardId,
            data: {
              type: store.state.MMCMQTT.orders.航线一键起飞,
              data: {
                taskId: state.airlineData.id,
                seq: 0
              }
            },
            callback() {
              data.callback && data.callback(true);
            }
          });
        }, 3000);
      } catch (e) {
        console.log('一键起飞失败', e);
        data.callback && data.callback(false);
      }
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '航线上传',
        data: {
          cmdValue: state.airlineData?.id
        },
        deviceHardId: state.uav.hardId
      });

      // 起飞指令
      setTimeout(() => {
        store.dispatch('MMCGroundStation/order', {
          order: '起飞',
          data: {},
          deviceHardId: state.uav.hardId
        });
        data.callback && data.callback(true);
      }, 3000);
    }
  },
  /**
   * 任务结束
   * @param {function} data.callback  //完成回调
   */
  async takeEnd({ state }, data) {
    console.log(state.airlineData, data);
    if (state.uav.network == 1) {
      try {
        // 结束航线指令
        store.dispatch('MMCMQTT/publish', {
          topic: 'PX4/OBTAIN/' + state.uav.hardId,
          data: {
            type: 525,
            data: null
          },
          callback() {
            data.callback && data.callback(true);
          }
        });
      } catch (e) {
        console.log('任务结束失败', e);
        data.callback && data.callback(false);
      }
    } else {
      try {
        // //地面站链路
        store.dispatch('MMCGroundStation/order', {
          order: '结束平台任务',
          data: {
            cmdFunction: 2280,
            taskId: state.airlineData.id
          },
          deviceHardId: state.uav.hardId
        });
      } catch (e) {
        console.log('任务结束失败', e);
        data.callback && data.callback(false);
      }

      // //起飞指令
      // setTimeout(() => {
      //   store.dispatch("MMCGroundStation/order", {
      //     order: "起飞",
      //     data: {},
      //     deviceHardId: state.uav.hardId,
      //   });
      //   data.callback && data.callback(true);
      // }, 3000);
    }
  },
  /**
   * 安全降落
   * @param {*} param0
   * @param {*} data
   */
  land({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.原地降落
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '降落',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
   * 暂停飞行
   * @param {*} param0
   * @param {*} data
   */
  pauseFly({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.暂停航线任务
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '悬停',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
   * 继续飞行
   * @param {*} param0
   * @param {*} data
   */
  continueFly({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.继续航线任务
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '航线模式',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
   * 返航
   * @param {*} param0
   * @param {*} data
   */
  returnFlight({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.返航
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '返航',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
   * 航线控制
   * @param {*} param0
   * @param {*} data
   */
  routeControl({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.航线控制,
          data: {
            latitude: data.latitude,
            longitude: data.longitude,
            speed: data.speed
          }
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      data.callback && data.callback(false);
      throw '地面站链路不支持指点移动';
    }
  },

  /**
   * 手动模式
   */
  modeManual({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'POSITION'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      data.callback && data.callback(true);
    }
  },
  /**
 * 跟随模式
 */
  modeAUTO_FOLLOW_TARGET({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'AUTO_FOLLOW_TARGET'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      data.callback && data.callback(true);
    }
  },
  /**
   * 自动模式
   */
  modeAuto({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'AUTO_MISSION'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      data.callback && data.callback(true);
    }
  },
  /**
 * 保持模式
 */
  modeHOLD({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'HOLD'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      data.callback && data.callback(true);
    }
  },
  /**
   * 挂载控制
   */
  mounteControl({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: 514,
          data: {
            mountId: data.mountId,
            payload: data.payload
          }
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    }
  },
  // 键盘控制
  keyControl({ state }, data) {
    console.log(data, '键盘控制');
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: 538,
          data: data
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    }
  },

  /**
   * 摇杆控制
   */
  modeLAND({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'LAND'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '指点飞行',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  changeYGPermissions({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: 534,
          data: {
            cmd: 31021,
            param1: 1,
            param2: 0,
            param3: 0,
            param4: 0,
            param5: 0,
            param6: 0,
            param7: 0
          }
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
    }
  },
  /**
   * 返航模式
   */
  modeAUTO_RTL({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'AUTO_RTL'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '指点飞行',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
   * 航线模式
   */
  modeAUTO_MISSION({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'AUTO_MISSION'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '指点飞行',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },

  /**
   * GPS/定点模式
   */
  modePOSITION({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.无人机模式切换,
          data: 'POSITION'
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      store.dispatch('MMCGroundStation/order', {
        order: '指点飞行',
        data: {},
        deviceHardId: state.uav.hardId
      });
      data.callback && data.callback(true);
    }
  },
  /**
    * 拍照
    */

  TakePhoto({ state }, data) {
    // 1016 清流视频拍照
    store.dispatch('MMCMQTT/publish', {
      topic: 'PX4/OBTAIN/' + state.uav.hardId,
      data: {
        data: {
          data: {
            videoID: 1,
          },
          messageID: 1016
        },
        type: 528,
      },
      callback() {
        data.callback && data.callback(true);
      }
    });
    // 1007 视频拍照
    store.dispatch('MMCMQTT/publish', {
      topic: 'PX4/OBTAIN/' + state.uav.hardId,
      data: {
        data: {
          data: {
            videoID: 1,
          },
          messageID: 1007
        },
        type: 528,
      },
      callback() {
        data.callback && data.callback(true);
      }
    });
  },
  /**
   * 录制视频
   */
  videoTranscribe({ state }, data) {
    if (state.uav.network == 1) {
      const streamData = {
        data: {
          data: {
            taskID: data.taskID,
            videoID: 1,
            name: data.name,
            dbID: data.dbID
          }
        },
        type: 528
      };
      // if (streamSelect == "QingLiu") {
      streamData.data.messageID = 1017;
      streamData.data.data.status = data.status;
      // } else {
      //   streamData.data.messageID = 1006;
      //   streamData.data.data.recordControl = this.record;
      // }
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        // data:{
        //     // videoID:1, //视频通道ID（需保持唯一）
        //     dbID:data.dbID, //历史记录id
        //     name:data.name, //挂载名称（如果知道挂载名称，将传名称即可，通道号可以不用传）
        //     // taskID:110, //任务ID，可传可不传
        //     recordControl:data.recordControl //录制开关、true为开启
        // },
        data: streamData,
        callback() {
          data.callback && data.callback(true);
        }
      });
    }
  },

  /**
   * 查询录制视频状态
   */
  videoTranscribeStatus({ state }, data) {
    if (state.uav.network == 1) {
      const streamData = {
        data: {
          data: {}
        },
        type: 528
      };
      // if (streamSelect == "QingLiu") {
      streamData.data.messageID = 1031;
      // } else {
      //   streamData.data.messageID = 1006;
      //   streamData.data.data.recordControl = this.record;
      // }
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: streamData,
        callback() {
          data.callback && data.callback(true);
        }
      });
    }
  },

  /**
   * 挂载指令
   * @param {*} param0
   * @param {*} data.buffer 指令数据
   * @param {*} data.mountId 挂载的id
   */
  mountDirective({ state }, data) {
    if (state.uav.network == 1) {
      store.dispatch('MMCMQTT/publish', {
        topic: 'PX4/OBTAIN/' + state.uav.hardId,
        data: {
          type: store.state.MMCMQTT.orders.云台控制指令can包透传,
          data: {
            mountId: data.mountId,
            payload: data.buffer
          }
        },
        callback() {
          data.callback && data.callback(true);
        }
      });
    } else {
      // 地面站链路
      const buff = data.buffer.join(',');
      store.dispatch('MMCGroundStation/publish', {
        type: 200,
        cmdFunction: null,
        data: {
          cmdControlType: 7000,
          cmdState: null,
          cmdValue: null,
          cmdBuffer: buff,
          cmdName: state.selectMount.gimbalName
        },
        deviceHardId: state.uav.hardId
      });
    }
  }
};

export default {
  namespaced: true,
  name: 'uavApplications',
  state,
  mutations,
  actions
};

/**
 * 生成飞机坐标与姿态信息
 * @param {number} data.longitude
 * @param {number} data.latitude
 * @param {number} data.height 海拔
 * @param {number} data.yaw
 * @param {number} data.pitch
 * @param {number} data.roll
 * @returns {} position
 * @returns {} orientation
 */
function UAVDataParser(data) {
  const position = Cesium.Cartesian3.fromDegrees(
    Number(data.locationCoordinate3D.longitude),
    Number(data.locationCoordinate3D.latitude),
    Number(data.gps.relativeAlt)
  );
  const hpr = new Cesium.HeadingPitchRoll(
    Cesium.Math.toRadians(data.attitude.yaw + 90),
    Cesium.Math.toRadians(data.attitude.pitch),
    Cesium.Math.toRadians(data.attitude.roll)
  );
  const orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr);
  return {
    position,
    orientation
  };
}
