const state = {
  uav: null, // 当前执行无人机信息
  showMenu: false, // 显示操作菜单
  currentEagle: undefined,
  showVideo: false
};

const mutations = {
  setState(state, { key, value }) {
    state[key] = value;
  }
};

const actions = {
  destroy({ commit }) {
    commit('setState', {
      key: 'uav',
      value: null
    });
    commit('setState', {
      key: 'showMenu',
      value: false
    });
    commit('setState', {
      key: 'currentEagle',
      value: undefined
    });
  }
};

export default {
  namespaced: true,
  name: 'uavApplicationsEagleNest',
  state,
  mutations,
  actions
};
