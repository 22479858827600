import request from '@/utils/request';

class projectAPI {
  // 查询全景图
  static dimensionMapPageAll(params) {
    return request({
      url: '/crm/dimensionMap/pageAll',
      method: 'GET',
      params
    });
  }
  // 获取列表
  static getProjectList(params) {
    return request({
      url: 'tmj/runProject/getRunProjectListSimple',
      method: 'GET',
      params
    });
  }
  // 资源类型列表
  static getPageBasicPropertyTypes(params) {
    return request({
      url: '/crm/basicProperty/getPageBasicPropertyTypes',
      method: 'GET',
      params
    });
  }
  // 资源列表
  static getPageBasicPropertyInfos(params) {
    return request({
      url: '/crm/basicProperty/getPageBasicPropertyInfos',
      method: 'GET',
      params
    });
  }
  // 删除项目
  static deleteProject(params) {
    return request({
      url: 'tmj/runProject/delete',
      method: 'DELETE',
      params
    });
  }
  // 添加项目
  static addProject(data) {
    return request({
      url: 'tmj/runProject/createRunProject',
      method: 'POST',
      data
    });
  }
  //  查询应用跟项目
  static getAppAndProject() {
    return request({
      url: 'crm/application/getAppAndProject',
      method: 'get'
    });
  }

  //  查询应用跟项目
  static getAppAndProject() {
    return request({
      url: 'crm/application/getAppAndProject',
      method: 'get'
    });
  }
  //  查询运行项目
  static getRunProject(id) {
    return request({
      url: `/crm/project/${id}`,
      method: 'get'
    });
  }
  //  子平台权限
  static getSubPlatformPermission(params) {
    return request({
      url: '/crm/user/subPlatformPermission',
      method: 'get',
      params
    });
  }
}

export default projectAPI;
