import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginAPI from '@/api/modules/login';
import store from '@/store';
import { Message } from 'element-ui';
import { getUrlParams } from '@/utils';
import { Base64 } from "js-base64";

Vue.use(VueRouter);

/* 通用routers*/
export const currencyRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/layout'),
    redirect: '/home',
    children: [
      {
        name: 'homeView',
        path: '/home',
        component: () => import('@/views/home')
      },
      {
        name: 'uavApplications',
        path: '/uavApplications',
        component: () => import('@/views/uavApplications')
      }
    ]
  }
];

/* 动态添加routers，二期会有路由权限功能*/
export const asyncRoutes = [];

// 解决在用vue-router 做单页应用的时候重复点击一个跳转的路由会出现报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const creatRouter = () => {
  return new VueRouter({
    mode: 'hash',
    routes: currencyRoutes,
    scrollBehavior: () => ({
      x: 0,
      y: 0
    })
  });
};

const router = creatRouter();

// 解决addRoute不能删除动态路由问题
export function resetRouter() {
  const reset = creatRouter();
  router.matcher = reset.matcher;
}

router.beforeEach(async (to, from, next) => {
  console.log('router.beforeEach', from, to);
  // const secretKey = to.query?.secretKey;
  // if (secretKey) {
  //   // 清理原有用户信息
  //   window.localStorage.setItem('user_info', '');
  //   window.localStorage.setItem('tmj_token', '');
  //   window.localStorage.setItem('permission', '');
  //   // 第三方平台使用授权码登录
  //   window.sessionStorage.setItem('secretKey', secretKey);
  //   window.sessionStorage.setItem('isIframe', '1'); // 记录为内嵌
  //   store.commit('SET_IS_IFRAME', '1');
  //   // 记录登录信息
  //   try {
  //     const resp = await LoginAPI.appSecretlogin({
  //       secretKey: secretKey
  //     });
  //     if (resp.code == 200) {
  //       if (resp.data) {
  //         window.localStorage.setItem('user_info', JSON.stringify(resp.data));
  //         window.localStorage.setItem('tmj_token', resp.data.token);
  //         store.commit('SET_USERINFO', resp.data); // 用户信息
  //         // 获取账户权限
  //         const pms = await LoginAPI.permission();
  //         store.commit('SET_PERMISSION', pms.data);
  //       }
  //     } else if (resp.code == 405) {
  //       Message({
  //         type: 'error',
  //         message: '账号已过有效期，请联系管理员！',
  //         duration: 1000
  //       });
  //     } else {
  //       /* Message({
  //         type: 'error',
  //         message: '登录失败',
  //         duration: 1000
  //       }); */
  //     }
  //   } catch (e) {
  //     Message({
  //       type: 'error',
  //       message: '登录失败',
  //       duration: 1000
  //     });
  //     console.log('登陆失败', e);
  //   }
  // } else {
  //   window.sessionStorage.setItem('secretKey', '');
  //   window.sessionStorage.setItem('isIframe', ''); // 记录为内嵌
  // }

  // 内嵌下不允许跳转到登录页
  // if (store.state.isIframe) {
  //   if (to.path === '/login') {
  //     return;
  //   }
  // }

  const token = localStorage.getItem('tmj_token');
  if(!token){
    if(to.path === '/home'){
      let urlParams = getUrlParams();
      if (urlParams) {
        const forms = new FormData();
        forms.append("userAccount", urlParams.username);
        forms.append("password", Base64.encode(urlParams.password));
        try {
          const resp = await LoginAPI.newLogin(forms);
          if (resp.code == 200) {
            if (resp.data) {
              window.localStorage.setItem(
                "user_info",
                JSON.stringify(resp.data)
              );
              window.localStorage.setItem("tmj_token", resp.data.token);
              this.SET_USERINFO(resp.data); // 用户信息
              // 获取账户权限
              const pms = await LoginAPI.permission(forms);
              store.commit('SET_PERMISSION', pms.data);
              this.$message({
                type: "success",
                message: "登录成功",
                duration: 1000,
              });
              this.timeShow();
              this.$router.push("/");
            }
          } else if (resp.code == 525) {
            // this.tooptip = '密码错误，请重新输入'
            // this.tag_state = false
            this.$message({
              type: "error",
              message: resp.msg,
              duration: 1000,
            });
          } else if (resp.code == 405) {
            this.$message({
              type: "error",
              message: "账号已过有效期，请联系管理员！",
              duration: 1000,
            });
            this.tooptip = "";
          } else {
            this.$message({
              type: "error",
              message: resp.msg,
              duration: 1000,
            });
          }
        } catch (e) {
        } finally {
          // this.loading = false;
        }
      } else{
        next('/login')
      }   
    } else{
      if(to.path === '/login'){
        next()
      } else{
        next('/login')
      }
    }
  } else{
    next()
  }

  // if (to.path === '/home' && !token) {
  //   let urlParams = getUrlParams();
  //   if (urlParams) {
  //     const forms = new FormData();
  //     forms.append("userAccount", urlParams.username);
  //     forms.append("password", Base64.encode(urlParams.password));
  //     try {
  //       const resp = await LoginAPI.newLogin(forms);
  //       if (resp.code == 200) {
  //         if (resp.data) {
  //           window.localStorage.setItem(
  //             "user_info",
  //             JSON.stringify(resp.data)
  //           );
  //           window.localStorage.setItem("tmj_token", resp.data.token);
  //           this.SET_USERINFO(resp.data); // 用户信息
  //           // 获取账户权限
  //           const pms = await LoginAPI.permission(forms);
  //           store.commit('SET_PERMISSION', pms.data);
  //           this.$message({
  //             type: "success",
  //             message: "登录成功",
  //             duration: 1000,
  //           });
  //           this.timeShow();
  //           this.$router.push("/");
  //         }
  //       } else if (resp.code == 525) {
  //         // this.tooptip = '密码错误，请重新输入'
  //         // this.tag_state = false
  //         this.$message({
  //           type: "error",
  //           message: resp.msg,
  //           duration: 1000,
  //         });
  //       } else if (resp.code == 405) {
  //         this.$message({
  //           type: "error",
  //           message: "账号已过有效期，请联系管理员！",
  //           duration: 1000,
  //         });
  //         this.tooptip = "";
  //       } else {
  //         this.$message({
  //           type: "error",
  //           message: resp.msg,
  //           duration: 1000,
  //         });
  //       }
  //     } catch (e) {
  //     } finally {
  //       // this.loading = false;
  //     }
  //   } else{
  //     next('/login')
  //   }
  // }
  // next();
});

export default router;
