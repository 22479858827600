<template>
  <div class="app">
    <!-- <Header v-if="isElectron"/> -->
    <div class="main">
      <router-view v-if="isRouterAlive" />
      <!-- <Layout /> -->
    </div>
  </div>
</template>

<script>
// import Header from '@/components/header'
import Layout from "@/components/layout";
import { LoginAPI } from "@/api";

export default {
  provide() {
    return {
      reload: this.reload
    };
  },

  components: { Layout },
  data() {
    return {
      isRouterAlive: true
    };
  },
  created() {
    // 每一小时刷新一次权限,避免权限过期
    setInterval(() => {
      LoginAPI.permission();

      let projectId;
      try {
        const subPlatformInfo = JSON.parse(localStorage.getItem('subplatformInfo'));
        projectId = subPlatformInfo.projectId;
      } catch (e) {
        console.log(e);
      }

      if (projectId) {
        LoginAPI.managePermission({
          projectId
        });
        LoginAPI.subPlatformPermission({
          projectId
        });
      }
    }, 1000 * 60 * 60);
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    z-index: 99999;
    height: 48px;
    background: #272b43;
    -webkit-app-region: drag;
    user-select: none;
    text-align: right;
    .el-icon-minus,
    .el-icon-close,
    .el-icon-plus {
      width: 48px;
      height: 48px;
      color: #74b1be;
      text-align: center;
      line-height: 48px;
      -webkit-app-region: no-drag;
    }
  }
  .main {
    flex: 1;
    position: relative;
    // background-color: #0a0b0d;
  }
}
</style>
