import request from '@/utils/request_tg';
import request_uav from '@/utils/request_uav';
import request_uas from '@/utils/request';
class Control_API {
  /**
   * 无人机token登录
   * @param {*} data
   * @returns
   */
  static login(data) {
    return request({
      url: '/crm/account/loginInfo',
      method: 'post',
      data,
      headers: {
        token: window.localStorage.getItem('tmj_token')
      }
    });
  }
  /**
   * 无人机接管更新状态
   * @param {*} id //设备id
   * @returns
   */

  static updateTakeOver(id) {
    return request({
      url: `/dms/uav/updateTakeOver/${id}`,
      method: 'put'
    });
  }
  // 获取无人机树结构列表
  static getUavTree(params) {
    return request({
      url: '/dms/uav/tree',
      method: 'get',
      params
    });
  }

  // 获取无人机列表
  static getUavDataList(params) {
    return request({
      url: '/dms/uav/page',
      method: 'get',
      params
    });
  }

  // 获取航线列表
  static getUavRouteList(params) {
    return request({
      url: '/dms/route/page',
      method: 'get',
      params
    });
  }

  // 获取架次号
  static getFlightSortic({ taskId, deviceHardId }) {
    return request({
      url: `/tss/task/getFlightSortic/${taskId}/${deviceHardId}`,
      method: 'get'
    });
  }

  // 无人机详情
  static uavDetail(params) {
    return request({
      url: `/dms/uav/detail/${params.id}`,
      method: 'get'
    });
  }

  // 飞行日志
  static getFlightLog(data) {
    return request({
      url: `/dms/sortie/flightLog`,
      method: 'post',
      data
    });
  }

  // 保存图片
  static addPhoto(data) {
    return request({
      url: `/dms/sortie-image/add`,
      method: 'post',
      data
    });
  }
  static opengasRecords(mountHardId) {
    return request_uas({
      url: `/uas/gasRecords/topic/${mountHardId}`,
      method: 'post',
    });
  }
  // 关闭订阅
  static delgasRecords(mountHardId) {
    return request_uas({
      url: `/uas/gasRecords/topic/${mountHardId}`,
      method: 'delete',
    });
  }
  // 记录分页列表
  static gasRecords(params) {
    return request({
      url: `/uas/gasRecords`,
      method: 'get',
      params
    });
  }
  // 保存AI图片
  // static addAiPhoto(data) {
  //   return request({
  //     url: `/dms/sortie-image/addAi`,
  //     method: "post",
  //     data
  //   });
  // }
  // 获取所有机构列表
  static getAllOrg() {
    return request({
      url: `/crm/organization/tree`,
      method: 'get'
    });
  }
  // 获取所有无人机操作日志
  static getUavAllLog(params) {
    return request_uav({
      url: `/api/log/selectUavCmdLikeList`,
      method: 'get',
      params
    });
  }
  // 获取1小时短时天气预报
  static getUavShortForEcast(params) {
    return request_uav({
      url: `/whapi/json/aliweather/shortforecast`,
      method: 'post',
      params
    });
  }
  // 获取24小时短时天气预报(详细)包含风向等等...
  static getUavShortForEcast24(params) {
    return request_uav({
      url: `/whapi/json/aliweather/forecast24hours`,
      method: 'post',
      params
    });
  }
  // 获取树结构-鹰巢
  static getUavNestList(params) {
    return request({
      url: `/dms/nest/getNestList`,
      method: 'get',
      params
    });
  }
  // 获取任务库列表
  static getTaskList(data) {
    return request({
      url: `/tss/task/list`,
      method: 'post',
      data
    });
  }
  // 获取任务库内的任务详情
  static getTaskDetails(params) {
    return request({
      url: `/tss/task/${params.id}`,
      method: 'get'
    });
  }
  // 鹰巢-定时，周期自启动
  static startUavNest = (data) =>
    request({
      url: '/tss/task/start',
      method: 'POST',
      data
    });
  //   鹰巢-运行日志
  static getUavNestLog = (params) =>
    request_uav({
      url: `api/log/selectProcessLikeList`,
      method: 'get',
      params
    });
}

export default Control_API;
