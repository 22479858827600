import Vue from 'vue';

Vue.directive('hover', {
  inserted(el) {
    el.style.cursor = 'pointer';
    el.onmouseover = function () {
      el.style.opacity = 0.7;
    };
    el.onmouseout = function () {
      el.style.opacity = 1;
    };
  }
});
