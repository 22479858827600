export default {
  type_list: [
    {
      id: 1,
      title: '巡查'
    },
    {
      id: 2,
      title: '预警'
    },
    {
      id: 3,
      title: '侦察'
    },
    {
      id: 4,
      title: '服务'
    },
    {
      id: 5,
      title: '救援'
    },
    {
      id: 6,
      title: '处置'
    },
    {
      id: 7,
      title: '打击'
    },
    {
      id: 8,
      title: '宣传'
    }
  ],
  list: [
    {
      title: '高清变焦相机',
      icon: require('@/assets/images/observe/001.svg'),
      name: 'DJI_H20T',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./DJI_H20T')
    },
    {
      title: '高清变焦相机',
      icon: require('@/assets/images/observe/001.svg'),
      name: 'MMC_Gimbal_Z40',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z40')
    },
    {
      title: '高清变焦相机',
      icon: require('@/assets/images/observe/001.svg'),
      name: 'MMC_Gimbal_Z40N',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z40N')
    },
    {
      title: '高清变焦相机Z33N',
      icon: require('@/assets/images/observe/001.svg'),
      name: 'MMC_Gimbal_Z33N',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z33N')
    },
    // Z40高清相机
    {
      title: '高清变焦相机Z20',
      icon: require('@/assets/images/observe/014.png'),
      name: 'MMC_Gimbal_Z20',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z20')
    },
    // Z40S高清相机
    {
      title: '高清变焦相机Z40S',
      icon: require('@/assets/images/observe/MMC_Gimbal_Z40S.png'),
      name: 'MMC_Gimbal_Z40S',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z40S')
    },
    // ZT60R高清相机
    {
      title: '高清变焦相机ZT60R',
      icon: require('@/assets/images/observe/MMC_Gimbal_ZT60R.png'),
      name: 'MMC_Gimbal_ZT60R',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_ZT60R/index.vue')
    },
    // ZT60R高清相机
    {
      title: '高清变焦相机ZT60R',
      icon: require('@/assets/images/observe/MMC_Gimbal_Z60R.png'),
      name: 'MMC_Gimbal_Z60R',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z60R/index.vue')
    },
    // Z30Pro高清相机
    {
      title: '高清变焦相机Z30Pro',
      icon: require('@/assets/images/observe/015.png'),
      name: 'MMC_Gimbal_Z30Pro',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_Z30Pro/index.vue')
    },
    {
      title: '喊话器',
      icon: require('@/assets/images/observe/002.svg'),
      name: 'MMC_Gimbal_P0_Pro',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./MMC_Gimbal_P0_Pro')
    },
    {
      title: '喊话器',
      icon: require('@/assets/images/observe/002.svg'),
      name: 'MMC_Gimbal_P1_4G',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./PagerP1_New')
    },
    {
      title: '喊话器',
      icon: require('@/assets/images/observe/002.svg'),
      name: 'MMC_Gimbal_P3',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./PagerP1_New')
    },
    {
      title: '探照灯',
      icon: require('@/assets/images/observe/004.svg'),
      name: 'MMC_Gimbal_L60',
      type: [3, 4],
      component: () => import('./MMC_Gimbal_L60')
    },
    // 探照灯L50
    {
      title: '探照灯',
      icon: require('@/assets/images/observe/016.png'),
      name: 'MMC_Gimbal_L50',
      type: [3, 4],
      component: () => import('./MMC_Gimbal_L50')
    },
    {
      title: '红外热成像',
      icon: require('@/assets/images/observe/003.svg'),
      name: 'MMC_Gimbal_ZT1',
      type: [3, 4, 5, 6],
      component: () => import('./MMC_Gimbal_ZT1')
    },
    {
      title: '抛投',
      icon: require('@/assets/images/observe/006.svg'),
      name: 'MMC_Gimbal_S1',
      type: [5, 6],
      component: () => import('./MMC_Gimbal_S1')
    },
    {
      title: '喷火器',
      icon: require('@/assets/images/observe/008.png'),
      name: 'MMC_Gimbal_FF6',
      type: [5],
      component: () => import('./MMC_Gimbal_FF6')
    },
    {
      title: '水体采样器',
      icon: require('@/assets/images/observe/017.png'),
      name: 'MMC_Gimbal_QS',
      type: [5],
      component: () => import('./MMC_Gimbal_QS')
    },
    {
      title: '双光',
      icon: require('@/assets/images/observe/007.svg'),
      name: 'MMC_Gimbal_ZT30N',
      type: [6],
      component: () => import('./MMC_Gimbal_ZT30N')
    },
    {
      title: '霹雳火',
      icon: require('@/assets/images/observe/005.svg'),
      name: 'MMC_Gimbal_S79',
      type: [7],
      component: () => import('./MMC_Gimbal_S79')
    },
    {
      title: '催泪弹',
      icon: require('@/assets/images/observe/009.svg'),
      name: 'MMC_Gimbal_FE8',
      type: [7, 8],
      component: () => import('./MMC_Gimbal_FE8')
    },
    {
      title: '破窗灭火器',
      icon: require('@/assets/images/observe/010.svg'),
      name: 'MMC_Gimbal_FB1',
      type: [7],
      component: () => import('./MMCGimbalFB1')
    },
    {
      title: '网枪',
      icon: require('@/assets/images/observe/011.svg'),
      name: 'MMC_Gimbal_FN3',
      type: [7, 8],
      component: () => import('./MMCGimbalFN3')
    },
    {
      title: '喊话器P3',
      icon: require('@/assets/images/observe/002.svg'),
      name: 'MMC_Gimbal_P3',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./PagerP1_New')
    },
    {
      title: '驱散器',
      icon: require('@/assets/images/observe/018.png'),
      name: 'MMC_Gimbal_P4',
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import('./PagerP1_New')
    },
    {
      title: '干粉灭火器',
      icon: require('@/assets/images/observe/019.svg'),
      name: 'MMC_Gimbal_FE3',
      component: () => import('./MMC_Gimbal_FE3')
    },
    {
      title: '多气体检测',
      icon: require('@/assets/images/observe/MMC_Gimbal_GAS.svg'),
      name: 'MMC_Gimbal_G6',
      component: () => import('./MMC_Gimbal_GAS/index.vue')
    },
    {
      title: '水文仪',
      icon: require('@/assets/images/observe/swy.png'),
      name: 'MMC_Gimbal_R3',
      component: () => import('./MMC_Gimbal_R3/index.vue')
    },
    {
      title: 'MMC_Gimbal_P20',
      icon: require('@/assets/images/observe/MMC_Gimbal_P20.png'),
      name: 'MMC_Gimbal_P20',
      component: () => import('./MMC_Gimbal_P20/index.vue')
    },
    {
      title: 'MMC_Gimbal_P30',
      icon: require('@/assets/images/observe/MMC_Gimbal_P20.png'),
      name: 'MMC_Gimbal_P20',
      component: () => import('./MMC_Gimbal_P30/index.vue')
    },
    {
      title: 'MMC_Gimbal_R4',
      icon: require('@/assets/images/observe/MMC_Gimbal_P20.png'),
      name: 'MMC_Gimbal_R4',
      component: () => import('./MMC_Gimbal_R4/index.vue')
    },
    {
      title: 'MMC_Gimbal_Z40KN',
      icon: require('@/assets/images/observe/MMC_Gimbal_Z40KN.png'),
      name: 'MMC_Gimbal_Z40KN',
      component: () => import('./MMC_Gimbal_Z40KN/index.vue')
    },
    {
      title: 'MMC_Gimbal_A10T',
      icon: require('@/assets/images/observe/MMC_Gimbal_Z40KN.png'),
      name: 'MMC_Gimbal_A10T',
      component: () => import('./MMC_Gimbal_A10T/index.vue')
    },
    {
      title: 'MMC_Gimbal_HT40R',
      icon: require('@/assets/images/observe/MMC_Gimbal_HT40R.png'),
      name: 'MMC_Gimbal_HT40R',
      component: () => import('./MMC_Gimbal_HT40R/index.vue')
    }
  ]
};
