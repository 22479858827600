import request from '@/utils/request';

class ThreeDimensionalApi {
  /**
   * ai列表
   * @param {*} params
   * @returns
   */

  static getAiIconListFromAdmin(params) {
    return request({
      url: '/crm/algorithm/algorithms',
      method: 'get',
      params
    });
  }

  // 获取无人机列表
  static getUavList(params) {
    return request({
      // url: "/tmj/servicePoint/getServicePointListMap",
      // url: '/tmj/fly/getflyListMap',
      url: '/dms/uav/page',
      method: 'get',
      params
    });
  }

  // 获取飞手列表
  static getFlyHandList(params) {
    return request({
      url: '/tmj/flyMan/getflyManListMap',
      method: 'get',
      params
    });
  }

  // 获取服务网点数据
  static getTrainPointListMap(params) {
    return request({
      url: '/tmj/trainPoint/getTrainPointListMap',
      method: 'get',
      params
    });
  }

  // 获取培训网点数据
  static getServicePointListMap(params) {
    return request({
      url: '/tmj/servicePoint/getServicePointListMap',
      method: 'get',
      params
    });
  }

  // 获取展示网点数据
  static getExhibitionPointListMap(params) {
    return request({
      url: '/tmj/exhibitionPoint/getExhibitionPointListMap',
      method: 'get',
      params
    });
  }

  // 获取培训运行项目列表
  static getRunProjectListSimple(params) {
    return request({
      // url: '/tmj/runProject/getRunProjectListSimple',
      url: '/crm/application/getAppAndProject',
      method: 'get',
      params
    });
  }

  // 获取架次列表
  static getSortie(params) {
    return request({
      url: '/dms/sortie/page',
      method: 'get',
      params
    });
  }
  // 获取架次图片列表
  static getSortieImage(params) {
    return request({
      url: '/dms/sortie-image/page',
      method: 'get',
      params
    });
  }
  // 获取架次视频列表
  static getSortieVideo(params) {
    return request({
      url: '/dms/sortie/history-video',
      method: 'get',
      params
    });
  }
  // 导出成果
  static getExportToWord(params) {
    return request({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
      url: `/dms/sortie/history-video`,
      method: 'GET',
      params
    });
  }

  // 获取图层列表
  static getGroupMap(params) {
    return request({
      url: '/crm/dimensionMap/getGroupMap',
      method: 'get',
      params
    });
  }
  // 获取标注列表
  static getmarkerList(params) {
    return request({
      url: '/crm/dimensionMark/pageAll',
      method: 'get',
      params
    });
  }
  // 添加标注
  static addMarker(data) {
    return request({
      url: '/crm/dimensionMark/addMark',
      method: 'post',
      data
    });
  }
  // 编辑标注
  static editMarker(data) {
    return request({
      url: '/crm/dimensionMark/updateMark',
      method: 'put',
      data
    });
  }
  // 删除标注
  static removeBatch(params) {
    return request({
      url: '/crm/dimensionMark/delMark',
      method: 'delete',
      params
    });
  }
  // 标注报告
  static getMarkReport(data) {
    return request({
      url: '/crm/dimensionMark/getMarkReport',
      method: 'post',
      data,
      responseType: 'blob'
    });
  }
  // 获取标签列表
  static getAllLabels(params) {
    return request({
      url: '/crm/dimensionLabel/getAllLabels',
      method: 'get',
      params
    });
  }
  // 添加标签
  static tagAdd(data) {
    return request({
      url: '/crm/dimensionLabel/addLabbel',
      method: 'post',
      data
    });
  }
  // 删除标签
  static dropAdd(params) {
    return request({
      url: '/crm/dimensionLabel/delLabbel',
      method: 'delete',
      params
    });
  }
  // 获取标签组
  static getTagGroupList(params) {
    return request({
      url: '/crm/dimensionLabel/getGroupAll',
      method: 'get',
      params
    });
  }

  // 添加标签组
  static addTagGroup(data) {
    return request({
      url: '/crm/dimensionLabel/addLabbelGroup',
      method: 'post',
      data
    });
  }
  // 编辑标签组
  static updateGroup(data) {
    return request({
      url: '/crm/dimensionLabel/updateLabbelGroup',
      method: 'put',
      data
    });
  }
  // 编辑标签组
  static updateLabbel(data) {
    return request({
      url: '/crm/dimensionLabel/updateLabbel',
      method: 'put',
      data
    });
  }
  // 删除标签组
  static tagGroupDelete(params) {
    return request({
      url: '/crm/dimensionLabel/delLabbelGroup',
      method: 'delete',
      params
    });
  }
  // 标注区域统计
  static getMarkChart(data) {
    return request({
      url: '/crm/dimensionMark/getMarkChart',
      method: 'post',
      data
    });
  }
  // 淹没分析
  static getMarkWaterChart(data) {
    return request({
      url: '/crm/dimensionMark/getMarkWaterChart',
      method: 'post',
      data
    });
  }
  // 标注区域统计标注报告
  static getMarkChartReport(data) {
    return request({
      url: '/crm/dimensionMark/getMarkChartReport',
      responseType: 'blob',
      method: 'post',
      data
    });
  }
}

export default ThreeDimensionalApi;
