import request from '@/utils/request';
class EarlyWarningAPI {
  //     水位预警-阈值
  static alertInformation = () =>
    request({
      url: '/dms/alertInformation/page',
      method: 'post'
    });
  // 水位预警饼图，柱状图数据
  static getMarkWaterWarringChart = (params) =>
    request({
      url: '/crm/dimensionMark/getMarkWaterWarringChart',
      method: 'get',
      params
    });
  //   水位预警-表格数据
  static getMarkWaterWarringChartList = (params) =>
    request({
      url: '/crm/dimensionMark/getMarkWaterWarringChartList',
      method: 'get',
      params
    });
  //   水位预警-总数据
  static getAllMarkWaterWarringChart = (params) =>
    request({
      url: '/crm/dimensionMark/getAllMarkWaterWarringChart',
      method: 'get',
      params
    });
  //   水位预警-详情数据
  static getMarkWaterWarringChartInfo = (params) =>
    request({
      url: '/crm/dimensionMark/getMarkWaterWarringChartInfo',
      method: 'get',
      params
    });
  //   水位预警-导出
  static exportMarkWaterWarring = (params) =>
    request({
      url: '/crm/dimensionMark/exportMarkWaterWarring',
      method: 'get',
      responseType: 'blob',
      params
    });
  //   气体预警-数据统计
  static getMarkGasWarringChart = (params) =>
    request({
      url: '/uas/gasAlertRecords/overview',
      method: 'get',
      params
    });
  //   气体预警-分页
  static getGasGroupByMount = (params) =>
    request({
      url: '/uas/gasAlertRecords/groupByMount',
      method: 'get',
      params
    });
  //   气体预警-详情
  static getGasAlertRecords = (params) =>
    request({
      url: '/uas/gasAlertRecords',
      method: 'get',
      params
    });
  //   气体预警-导出
  static exportMarkGasWarring = (params) =>
    request({
      url: '/uas/gasAlertRecords/download',
      method: 'get',
      responseType: 'blob',
      params
    });
  //   三维监测-参数
  static getMarkMonitorList = (params) => {
    return request({
      url: '/crm/dimensionMark/getMarkMonitorList',
      method: 'get',
      params
    });
  };
}
export default EarlyWarningAPI;
