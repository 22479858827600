import { User } from '@/api';
import {
  measure,
  measureElevation,
  measureTypes,
  drawGraphic,
  getArea
} from '@/utils/cesium/measure';

let handler = null;
let current = null;
let entities = [];
let wallEntities = [];
const backShapePoints = [];
let lonlats = [];
let baseHeight = 0;
let triangle = [];
let minWallHeight = 0;
let maxWallHeight = 0;
let excavateVolume = '';
let buryVolumes = '';
let labelEntity = null;
let baseEntity = null;
let showVolumeDiaolog = false;
let draw = false;

export default {
  handle_click(e, val) {
    if (!this.isCesium) {
      return;
    }
    let viewer = window.viewer;
    if (window.viewers) {
      const index = this.$store.state.uavApplications.mapRadio;
      viewer = window.viewers[index];
    }
    switch (e) {
      case 1:
        this.toolbar_flyto_origin(viewer);
        break;
      case 2:
        this.toolbar_set_origin(viewer);
        break;
      case 3:
        this.toolbar_measure(viewer, 1);
        break;
      case 4:
        this.toolbar_measure(viewer, 2);
        break;
      case 5:
        this.toolbar_measure(viewer, 3);
        break;
      case 6:
        this.toolbar_measure(viewer, 4);
        break;
      case 7:
        this.toolbar_view_top(viewer);
        break;
      case 8:
        this.toolbar_view_look_down(viewer);
        break;
      case 9:
        this.toolbar_view_north(viewer);
        break;
      case 10:
        this.toolbar_view_eyes(viewer);
        break;
      case 11:
        this.factor_chage_not_fly_area(val);
        break;
    }
  },

  /**
   * 飞入原点
   */
  async flyto_origin(viewer) {
    let res = await User.GetOrigin({ userId: this.userInfo.id });
    if (!res.data) {
      return this.$message(this.$t('layout.NoSetOrigin'));
    }
    this.$store.commit('SET_CESIUM_STATE', true);
    res = res.data;
    const data = {
      destination: new Cesium.Cartesian3(res.lng, res.lat, res.height || 300), // 相机位置
      orientation: {
        heading: res.heading || 0,
        pitch: res.pitch || 0,
        roll: res.roll || 0
      }
    };
    viewer.camera.flyTo(data);
  },

  /**
   * 设置原点
   */
  async set_origin(viewer) {
    const userId = this.userInfo.id;
    const {
      camera: {
        position: { x, y, z },
        heading,
        pitch,
        roll
      }
    } = viewer;
    User.SetOrigin({
      lng: x,
      lat: y,
      height: z,
      heading,
      pitch,
      roll,
      userId
    }).then(() =>
      this.$message({
        showClose: true,
        type: 'success',
        message: this.$t('layout.setSuccess'),
        duration: 1000
      })
    );
  },

  measure(viewer, cate) {
    const { toolbar_handle_measure } = this;
    if (cate == 1) {
      toolbar_handle_measure(viewer, measureTypes.HORL);
    } else if (cate == 2) {
      toolbar_handle_measure(viewer, measureTypes.VERL);
    } else if (cate == 3) {
      toolbar_handle_measure(viewer, measureTypes.AREA);
    } else if (cate == 4) {
      toolbar_handle_measure(viewer, measureTypes.VOLUME);
    }
  },

  handle_measure(viewer, type) {
    const drawingMode = type === measureTypes.AREA ? 'polygon' : 'line';

    if (wallEntities) {
      wallEntities.forEach((entity) => {
        viewer.entities.remove(entity);
      });
      wallEntities = null;
    }

    if (handler && handler.destroy) {
      entities.forEach((item) => {
        viewer.entities.remove(item);
      });
      if (!handler.isDestroyed()) handler.destroy(); // 关闭绘制事件句柄
      // viewer.scene.globe.depthTestAgainstTerrain = false; // 关闭地形深度检测
      handler = null;
    }

    if (current !== type) {
      if (type == measureTypes.VOLUME) {
        showVolumeDiaolog = true;
        this.toolbar_handle_measure_volume(viewer);
      } else if (type === measureTypes.VERL) {
        handler = measureElevation(viewer, (backShapePoints, backShapeEntities) => {
          current = '';
          entities = backShapeEntities;
          // viewer.scene.globe.depthTestAgainstTerrain = false; // 关闭地形深度检测
        });
      } else {
        handler = measure(viewer, drawingMode, (backShapePoints, backShapeEntities) => {
          entities = backShapeEntities;
          // let position = Cesium.Cartesian3.lerp(backShapePoints[0], backShapePoints[1], 0.5, new Cesium.Cartesian3())
          const position = Cesium.Cartesian3.midpoint(
            backShapePoints[0],
            backShapePoints[1],
            new Cesium.Cartesian3()
          );

          const cartographic = Cesium.Cartographic.fromCartesian(position);

          viewer.entities.add({
            position,
            point: {
              pixelSize: 6,
              color: Cesium.Color.GREEN,
              outlineColor: Cesium.Color.GREEN,
              outlineWidth: 0
            }
          });
        });
      }

      current = type;
    } else {
      current = '';
      showVolumeDiaolog = false;
    }
  },

  handle_measure_volume(viewer) {
    draw = true;

    drawGraphic(viewer, 'polygon', (backShapePoints, backShapeEntities) => {
      if (backShapePoints.length < 3) {
        this.$message(this.$t('layout.selectAtLeastThree'));
        this.toolbar_handle_measure_volume(viewer);
        return false;
      }

      draw = false;
      backShapeEntities.forEach((entity) => {
        viewer.entities.remove(entity);
      });

      this.toolbar_volume_analysis(viewer, backShapePoints);

      handler = null;
      current = null;
      entities = [];
      // wallEntities = [];
      backShapePoints = [];
      lonlats = [];
      baseHeight = 0;
      triangle = [];
      minWallHeight = 0;
      maxWallHeight = 0;
      excavateVolume = '';
      buryVolumes = '';
      labelEntity = null;
      baseEntity = null;
    });
  },

  volume_analysis(viewer, points) {
    // viewer.scene.globe.depthTestAgainstTerrain = true; // 开启地形深度检测

    let minHeight = 10000;
    for (let i = 0; i < points.length; i++) {
      const cartographic = Cesium.Cartographic.fromCartesian(points[i]);
      const height = viewer.scene.sampleHeight(cartographic);

      if (minHeight > height) {
        minHeight = height;
      }

      // 经纬度值
      const lng = Cesium.Math.toDegrees(cartographic.longitude);
      const lat = Cesium.Math.toDegrees(cartographic.latitude);
      lonlats.push(lng, lat, 0);
    }

    // 设置网格粒度
    let granularity = Math.PI / Math.pow(2, 15);
    granularity /= 64;
    // 创建多边形平面几何体
    // @ts-ignore
    const polygonGeometry = new Cesium.PolygonGeometry.fromPositions({
      positions: points,
      vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
      granularity
    });

    // 创建多边形平面几何体
    // @ts-ignore
    const geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry);
    const totalVolume = 0;
    let maxHeight = 0;
    let i0;
    let i1;
    let i2;
    let height1;
    let height2;
    let height3;
    let p1;
    let p2;
    let p3;
    let cartesian;
    // @ts-ignore
    let cartographic;
    let bottomArea;
    let buryVolumes = 0;
    let excavateVolume = 0;
    let cartesian1;
    let cartesian2;
    let cartesian3;

    // 循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
    for (let i = 0; i < geom.indices.length && geom.indices.length < 10000; i += 3) {
      i0 = geom.indices[i];
      i1 = geom.indices[i + 1];
      i2 = geom.indices[i + 2];
      // 获取几何体三角面第一个面点
      cartesian1 = new Cesium.Cartesian3(
        geom.attributes.position.values[i0 * 3],
        geom.attributes.position.values[i0 * 3 + 1],
        geom.attributes.position.values[i0 * 3 + 2]
      );
      // 获取几何体三角面第二个面点
      cartesian2 = new Cesium.Cartesian3(
        geom.attributes.position.values[i1 * 3],
        geom.attributes.position.values[i1 * 3 + 1],
        geom.attributes.position.values[i1 * 3 + 2]
      );
      // 获取几何体三角面第三个面点
      cartesian3 = new Cesium.Cartesian3(
        geom.attributes.position.values[i2 * 3],
        geom.attributes.position.values[i2 * 3 + 1],
        geom.attributes.position.values[i2 * 3 + 2]
      );
      // @ts-ignore
      const polyCenter = Cesium.BoundingSphere.fromPoints([
        cartesian1,
        cartesian2,
        cartesian3
      ]).center;
      // @ts-ignore
      cartographic = Cesium.Cartographic.fromCartesian(polyCenter);

      const height = viewer.scene.sampleHeight(cartographic);

      // @ts-ignore
      if (maxHeight < height) {
        // @ts-ignore
        maxHeight = height;
      }

      // 计算三角面的面积
      // @ts-ignore
      bottomArea = getArea([cartesian1, cartesian2, cartesian3]);

      triangle.push({
        height,
        bottomArea
      });

      if (height > minHeight) {
        // @ts-ignore
        excavateVolume += bottomArea * (height - minHeight);
      }

      if (height < minHeight) {
        // @ts-ignore
        buryVolumes += bottomArea * (minHeight - height);
      }
    }

    // 创建体积墙
    const text = `${this.$t('layout.ExcavatedSquare')}：${excavateVolume.toFixed(1)} ${this.$t('layout.stere')}\n${this.$t('layout.fillSquare')}：${buryVolumes.toFixed(
      1
    )} ${this.$t('layout.stere')}`;
    this.toolbar_create_wall(Cesium, viewer, lonlats, minHeight, maxHeight, points, text);

    baseHeight = Number(minHeight.toFixed(2));
    minWallHeight = Number(minHeight.toFixed(2));
    maxWallHeight = Number(maxHeight.toFixed(2));
    excavateVolume = `${excavateVolume.toFixed(1)} ${this.$t('layout.stere')}`;
    buryVolumes = `${buryVolumes.toFixed(1)} ${this.$t('layout.stere')}`;
  },

  create_wall(Cesium, viewer, lonlats, minHeight, maxHeight, points, totalVolume) {
    const maxLonlats = lonlats.map((item) => {
      if (item === 0) {
        return maxHeight;
      }
      return item;
    });

    const centroid = Cesium.BoundingSphere.fromPoints(
      Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats)
    ).center;
    labelEntity = new Cesium.Entity({
      // @ts-ignore
      position: centroid,
      label: {
        // @ts-ignore
        text: totalVolume,
        font: '14pt Microsoft YaHei',
        // @ts-ignore
        showBackground: true,
        // @ts-ignore
        backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
        // @ts-ignore
        perPositionHeight: true,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(1.0, 50000.0),
        // @ts-ignore
        disableDepthTestDistance: 5000.0
      }
    });
    viewer.entities.add(labelEntity);

    // @ts-ignore
    const entity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats),
        material: Cesium.Color.CYAN.withAlpha(0.4),
        extrudedHeight: 0,
        // @ts-ignore
        perPositionHeight: true,
        closeTop: false,
        closeBottom: false
      }
    });
    viewer.entities.add(entity);

    const minLonlats = lonlats.map((item) => {
      if (item === 0) {
        return minHeight;
      }
      return item;
    });

    baseEntity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(minLonlats),
        material: Cesium.Color.GREEN.withAlpha(0.4),
        // @ts-ignore
        clampToGround: false,
        // @ts-ignore
        perPositionHeight: true
      }
    });
    viewer.entities.add(baseEntity);
    wallEntities = [labelEntity, entity, baseEntity];
  },

  /**
   * 顶视
   */
  view_top(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-90),
        roll: 0.0
      }
    });
  },

  /**
   * 俯视
   */
  view_look_down(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-45),
        roll: 0.0
      }
    });
  },

  /**
   * 指北
   */
  view_north(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: Cesium.Math.toRadians(0),
        pitch: viewer.camera.pitch,
        roll: 0.0
      }
    });
  },

  /**
   * 人视角
   */
  view_eyes(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-10),
        roll: 0.0
      }
    });
  }
};
