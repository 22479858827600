import axios from 'axios';
// import store from "../store";
// import router from "../router";
import Vue from 'vue';
// 引用element-ui的加载和消息提示组件
// import { Loading } from "element-ui";
import { resetMessage } from './message';
import store from '@/store';

const {reqHeaderMap} = store.state.locale

const $axios = axios.create({
  // 设置超时时间
  // timeout: 30000,
  // 基础url，会在请求url中自动添加前置链接
  baseURL: process.env.VUE_APP_BASE_UAV_API
});

Vue.prototype.$http = axios;

const loading = null;

/**
 * 请求拦截器
 * 用于处理请求前添加loading、判断是否已保存token，并在每次请求头部添加token
 */
$axios.interceptors.request.use(
  (config) => {
    // let FLYINGSESSIONID = localStorage.getItem("FLYINGSESSIONID");
    // let mmcIdentity = localStorage.getItem("mmcIdentity");
    // let token = localStorage.getItem("tmj_token")
    // loading = Loading.service({ text: "Loading", background: "transparent" });
    // if (FLYINGSESSIONID && mmcIdentity) {
    // 请求头部添加token
    // config.headers["FLYINGSESSIONID"] = FLYINGSESSIONID;
    // config.headers["mmc-identity"] = mmcIdentity;
    // config.headers["token"] = token; //测试token用例:607753147d46ba48b1ac30a4ad3d60cd
    // }
    config.headers['Locale'] = reqHeaderMap?.[store.state.locale?.curLocale]
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * 响应拦截器
 * 用于处理loading状态关闭、请求成功回调、响应错误处理
 */
$axios.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close();
    }
    const code = response.status;
    const res = response.data;
    // 请求成功返回response.data
    if ((code >= 200 && code < 300) || code === 304) {
      if (res.status == 621) {
        resetMessage.error(res.message);
        // store.commit("user/LOGIN_OUT");
        // router.push("/login");
      }
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    console.log(error);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回401 清除token信息并跳转到登陆页面
          // store.commit("user/LOGIN_OUT");
          // router.replace({
          //   path: "/login",
          //   query: {
          //     redirect: router.currentRoute.fullPath,
          //   },
          // });
          break;
        case 404:
          resetMessage.error('网络请求不存在');
          break;
        case 403:
          if (store.state.isIframe) {
            resetMessage.error('授权组件已被禁用，请联系管理员');
          }
          break;
        default:
          resetMessage.error(error.response.data.message);
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        resetMessage.error('请求超时！请检查网络是否正常');
      } else {
        resetMessage.error('请求失败，请检查网络是否已连接');
      }
    }
    return Promise.reject(error);
  }
);

export default $axios;
