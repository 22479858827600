/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:17:55
 * @LastEditors: 
 * @LastEditTime: 2024-11-09 16:44:30
 */
const requireApi = require.context(".", true, /.js$/);

const i18n = {};

const excludes = ['./index.js']; // 白名单文件

requireApi.keys().forEach((key, index)=> {
  if(!excludes.includes(key)) {
    const moduleName = key.replace(/(.*\/)*([^.]+).*/ig, "$2");
    i18n[moduleName] = requireApi(key).default;
  }
})

export default i18n;