import request_ai from '@/utils/request_ai';

class AiApi {
  /**
   * ai列表
   * @param {*} params
   * @returns
   */
  static getAiList(data) {
    return request_ai({
      url: '/detector/list',
      method: 'GET',
      data
    });
  }

  static getAiVideo(data) {
    return request_ai({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: '/detector/stream',
      method: 'POST',
      data
    });
  }

  // 销毁进程
  static destroyAi(data) {
    return request_ai({
      url: '/detector/killprocess',
      method: 'POST',
      data
    });
  }

  /**
   * ai图片列表
   * @param {*} params
   * @returns
   */
  static getAiPhotoList(data) {
    return request_ai({
      url: '/detect/list',
      method: 'GET',
      data
    });
  }

  /**
   * ai图片识别
   * @param {*} data
   * @returns
   */
  static getAiPhoto(data) {
    return request_ai({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: '/detect/image',
      method: 'POST',
      data
    });
  }
}

export default AiApi;
