const state = {
  subplatFormStatus: false, // 子平台显示
  subplatFormActive: 1, // 子平台显示
  mapStyle: null, // 地图大小样式 videoWallMapStyle 视频墙
  totalMaps: 1,
  linkage: false
};

const mutations = {
  SET_LINK_AGE(state, con) {
    state.linkage = con;
  },
  SET_MAP_STYLE(state, con) {
    state.mapStyle = con;
  },
  SET_TOTAL_MAPS(state, con) {
    state.totalMaps = con;
  },
  SET_SUBPLAT_FORM_STATUS(state, con) {
    state.subplatFormStatus = con;
  },
  SET_SUBPLAT_FORM_ACTIVE(state, con) {
    state.subplatFormActive = con;
  }
};

const actions = {};

export default {
  namespaced: true,
  name: 'subplatform',
  state,
  mutations,
  actions
};
