import toolbar from './toolbar';

function prefix(meta, prefix) {
  return Object.keys(meta).reduce((obj, key) => {
    obj[`${prefix}_${key}`] = meta[key];
    return obj;
  }, {});
}

export default {
  ...prefix(toolbar, 'toolbar')
};
