import request from '@/utils/request';

class VideoWell {
  /**
   *视频列表 登录状态
   * @param {*} params
   * @returns
   */
  static uavOnLine(params) {
    return request({
      url: '/dms/uav/onLine',
      method: 'get',
      params
    });
  }
  /**
   *视频列表 离线状态
   * @param {*} params
   * @returns
   */
  static uavSimulation(params) {
    return request({
      url: '/dms/uav/simulation',
      method: 'get',
      params
    });
  }
}

export default VideoWell;
