import axios from 'axios';

const key = 'f459934971d1f4b5dc3453a4420f4897';

class Map {
  static geo(params) {
    // let promise = axios.get('https://restapi.amap.com/v3/place/text?parameters', {
    return axios.get('https://restapi.amap.com/v3/assistant/inputtips', {
      params: {
        key,
        ...params,
        // city: "全国",
        page: '1',
        extensions: 'all'
      }
    });
  }
  // 逆地理编码
  static regeo(params) {
    return axios.get('https://restapi.amap.com/v3/geocode/regeo', {
      params: {
        key,
        ...params,
        page: '1',
        extensions: 'all'
      }
    });
  }
}

export default Map;
