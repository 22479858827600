<template>
  <div class="cpt-layout">
    <div style="height: 100%; width: 100%">
      <CesiumLayer ref="cesium_layer" />
      <!-- <CesiumLayer v-show="isCesium" ref="cesium_layer" /> -->
      <!-- <ChartsMap
        v-show="!isCesium"
        @changeIsCesiumState="changeIsCesiumState"
      /> -->
    </div>
    <!-- <Map2d v-show="!isMap3d" @changeIsCesiumState="changeIsCesiumState" /> -->

    <el-tooltip class="item" effect="dark" :content="$t('layout.SwitchMap')" placement="top-start">
      <div class="switch-map" :class="{ 'open-right': openRightNavSilder }" @click="onSwitchMap">
        <img :src="switchPNG" width="24px" height="24px" />
      </div>
    </el-tooltip>
    <CesiumToolbar
      ref="cesium_toolbar"
      class="cesium-toolbar"
      :class="{ 'open-right': openRightNavSilder }"
      @toolbar-click="toolbar_handle_click"
    />
    <!-- <CesiumToolbar
      ref="cesium_toolbar"
      class="cesium-toolbar"
      :class="{ 'open-right': openRightNavSilder }"
      :disabled="!isCesium"
      @toolbar-click="toolbar_handle_click"
    /> -->
    <demoVideo v-if="showDemo" />
    <router-view />
  </div>
</template>

<script>
import CesiumLayer from '@/components/cesium-layer';
import CesiumToolbar from '@/components/cesium-toolbar';
import Map2d from '@/components/map2d-layer';
import ChartsMap from '@/components/charts-map-layer';
import demoVideo from '@/components/demo-video';
import methods from './methods';
import { mapMutations, mapState } from 'vuex';
import switch1PNG from '@/assets/basic-tools/switch1.png';
import switch2PNG from '@/assets/basic-tools/switch2.png';
import switch3PNG from '@/assets/basic-tools/switch3.png';

export default {
  components: {
    CesiumLayer,
    CesiumToolbar,
    ChartsMap,
    Map2d,
    demoVideo
  },

  data() {
    return {
      wheelValue: 0,
      // isCesium: false,
      nowHeight: null,
      userInfo: null,
      firstSwitchPNG: true // 是否是第一次展示切换图片
    };
  },

  provide() {
    return {
      g_cesium_layer: () => this.cesium_layer,
      g_cesium_add_polyline: () => this.cesium_layer.add_polyline
    };
  },

  computed: {
    ...mapState(['isCesium', 'mapMode', 'openRightNavSilder', 'showDemo']),
    cesium_layer() {
      return this.$refs['cesium_layer'];
    },
    switchPNG() {
      // 已经打开夜视时
      if (this.mapMode) {
        // 第一次展示时，需要展示静态png
        if (this.firstSwitchPNG) {
          return switch3PNG;
        } else {
          return switch1PNG;
        }
      } else {
        return switch2PNG;
      }
    }
  },

  watch: {
    /* isCesium (val) {
      this.SET_CESIUM_STATE(val)
      console.log("isCesium...", val);
      this.$bus.$emit("handleCesiumState", val)
    } */
  },

  mounted() {
    this.getCameraHeight();
    this.handleMouseWheel();
    this.$bus.$on('handleZhedie', (e) => {
      if (!e) {
        this.$refs.cesium_toolbar.$el.style.left = '486px';
        this.$refs.cesium_toolbar.$el.style.transition = 'left 0.25s';
        // transition: width 1s, left 1s;
      } else {
        this.$refs.cesium_toolbar.$el.style.left = '103px';
        this.$refs.cesium_toolbar.$el.style.transition = 'left 0.25s';
      }
    });

    try {
      this.userInfo = JSON.parse(localStorage.getItem('user_info'));
    } catch (e) {
      this.userInfo = null;
    }

    if (this.userInfo) {
      this.toolbar_handle_click(1);
    }

    this.$bus.$on('flyToOrigin', () => {
      if (this.userInfo) {
        this.toolbar_handle_click(1);
      }
    });
  },

  methods: {
    ...methods,
    ...mapMutations(['SET_CESIUM_STATE', 'SET_MAP_MODE']),
    handleMouseWheel() {
      const self = this;
      document.onmousewheel = function (event) {
        // 注意 wheelDelta不是date
        // 此方法不兼容火狐浏览器
        self.getCameraHeight();
        // self.wheelValue += Number(event.wheelDelta);
        // console.log(Number(event.wheelDelta));
        // console.log(" this.nowHeight...", self.nowHeight);
        // if (self.wheelValue > 600) {
        //   self.isCesium = false;
        // } else {
        //   self.isCesium = true;
        // }
        if (Number(self.nowHeight) > 10000000) {
          self.SET_CESIUM_STATE(false);
        }
        if (!self.isCesium) {
          self.wheelValue -= Number(event.wheelDelta);
          console.log(self.wheelValue);
          if (self.wheelValue < -1000) {
            self.SET_CESIUM_STATE(true);
          }
        }
      };
    },
    changeIsCesiumState() {
      this.SET_CESIUM_STATE(true);
    },
    getCameraHeight() {
      const viewer = window.viewer;
      // console.log("viewer...", viewer.camera);
      const cartographic = viewer.camera.positionCartographic;
      const { height } = cartographic;
      this.nowHeight = height;
    },
    onSwitchMap() {
      this.firstSwitchPNG = false;
      this.SET_MAP_MODE(this.mapMode === 0 ? 1 : 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-layout {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.cesium-toolbar {
  // z-index: 1;
  transition: right 0.25s, opacity 0.25s;
  position: absolute;
  bottom: 8px;
  right: 5px;

  &.open-right {
    right: 366px;
  }
}

.switch-map {
  // z-index: 1;
  transition: right 0.25s, opacity 0.25s;
  position: absolute;
  bottom: 63px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid #3bc1e5;
  border-radius: 4px;
  cursor: pointer;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);

  &.open-right {
    right: 366px;
  }
}
</style>
