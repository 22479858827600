<!-- 2d全球地图 -->
<template>
  <div id="AMap" ref="AMap" style="height: 100%" />
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  data() {
    return {
      navName: '',
      option: {},
      itemList: [],
      provincesList: [],
      areaColor: '#1A232C',
      borderColor: '#24A4FF',
      map: {},
      loadCount: 0,
      screenWidth: document.body.clientWidth
    };
  },
  watch: {
    screenWidth() {
      setTimeout(function () {
        window.location.reload();
      }, 500);
    }
  },

  mounted() {
    this.initMap();
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    initMap() {
      console.log('initMap');
      window._AMapSecurityConfig = {
        securityJsCode: 'c7e195ff155155ba8a91baba21882a26'
      };
      AMapLoader.load({
        key: '547bb52bb626b1cb71b8b037e0e103e4', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map(this.$refs.AMap, {
            mapStyle: 'amap://styles/grey' // 设置地图的显示样式
          });
          console.log('initMap done', this.map);
        })
        .catch((e) => {
          console.log('amap error', e);
          if (this.loadCount++ >= 5) {
            // this.$message({
            //   type: 'error',
            //   message: '2D地图加载失败'
            // });
          } else {
            this.initMap();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#AMap {
  width: 100vw;
  height: 100vh !important;
}
</style>
